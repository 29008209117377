// @ts-ignore
/* eslint-disable */
import { request } from "umi";
import { Response } from "express";
import jwt_decode from "jwt-decode";
import { message } from "antd";

export function getHeaders() {
  const token = localStorage.getItem("access_token") ?? "";
  const authHeader = { Auth_token: `${token}` };
  return authHeader;
}

export function getHeadersWithFile() {
  const token = localStorage.getItem("access_token") ?? "";
  const authHeader = { Auth_token: `${token}` };
  return { ...authHeader, "Content-Type": "multipart/form-data" };
}

export const defaultHeader = () => ({
  "Content-Type": "application/json",
  Accept: "application/json"
});

let access = "";

const getAccess = () => {
  return access;
};

export function getUserDetail(): any {
  const token = localStorage.getItem("access_token") ?? "";
  if (token === "") {
    throw new Error("Please Login First！");
  }
  // console.log('getUserDetail token', token);
  var decoded = jwt_decode(token);
  console.log("getUserDetail", decoded);
  return decoded;
}

/** 获取当前的用户 GET /api/currentUser */
export async function currentUser(options?: { [key: string]: any }) {
  // return request<{
  //   data: API.CurrentUser;
  // }>(`/api/currentUser`, {
  //   method: 'GET',
  //   ...(options || {}),
  // });
  const token = localStorage.getItem("access_token") ?? "";
  if (token === "") {
    throw new Error("Please Login First！");
  }
  return {
    data: {
      name: "Serati Ma",
      avatar:
        "https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png",
      userid: "00000001",
      email: "antdesign@alipay.com",
      signature: "海纳百川，有容乃大",
      title: "交互专家",
      group: "蚂蚁金服－某某某事业群－某某平台部－某某技术部－UED",
      tags: [
        {
          key: "0",
          label: "很有想法的"
        },
        {
          key: "1",
          label: "专注设计"
        },
        {
          key: "2",
          label: "辣~"
        },
        {
          key: "3",
          label: "大长腿"
        },
        {
          key: "4",
          label: "川妹子"
        },
        {
          key: "5",
          label: "海纳百川"
        }
      ],
      notifyCount: 12,
      unreadCount: 11,
      country: "China",
      access: "admin",
      geographic: {
        province: {
          label: "浙江省",
          key: "330000"
        },
        city: {
          label: "杭州市",
          key: "330100"
        }
      },
      address: "西湖区工专路 77 号",
      phone: "0752-268888888"
    }
  };
}

/** 退出登录接口 POST /api/login/outLogin */
export async function outLogin(options?: { [key: string]: any }) {
  return request<Record<string, any>>(`/api/v3/auth/oms/logout`, {
    method: "GET",
    headers: getHeaders(),
    ...(options || {})
  }).then(res => {
    return {
      message: res.message,
      local: res.local,
      code: res.code,
      data: res.data
    };
  });
}

/** 登录接口 POST /api/login/account */ /**http://localhost:8000/user/login*/
export async function login(
  body: API.LoginParams,
  options?: { [key: string]: any }
) {
  return request<API.LoginResult>(`/api/auth/oms/login`, {
    method: "POST",
    // prefix: API_URL,
    headers: defaultHeader(),
    data: body,
    ...(options || {})
  });
}

export async function imageUpload(file: any) {
  const formData = new FormData();
  formData.append("file", file.originFileObj);
  formData.append("bucketEnum", "xidcard");

  return request<any>(`/api/v3/oms/upload`, {
    method: "POST",
    headers: getHeaders(),
    requestType: "form",
    data: formData,
    getResponse: true,
    errorHandler: error => {
      message.error(error.data.message ?? error.message);
    }
  });
}

export async function updateScrappedStatus(data: API.ScrappedStatusParams) {
  return request<any>(`/api/v3/oms/stock/device/scrapped`, {
    method: "POST",
    headers: getHeaders(),
    data: data,
    errorHandler: error => {
      message.error(error.data.message ?? error.message);
    }
  }).then(res => {
    return {
      message: res.message,
      local: res.local,
      code: res.code,
      data: res.data
    };
  });
}

export async function getCustomerDetail(id: string) {
  return request<any>(`/api/oms/customer/get`, {
    method: "GET",
    headers: getHeaders(),
    params: {
      customerId: id
    }
  });
}

export async function getProvinceList() {
  return request<any>(`/api/v3/oms/area/province`, {
    method: "GET",
    headers: getHeaders(),
    params: {
      areaCode: ""
    },
    errorHandler: error => {
      message.error(error.data.message ?? error.message);
    }
  });
}

// get all area list
export async function getAllAreaList() {
  return request<any>(`/api/v3/oms/area/allArea`, {
    method: "GET",
    headers: getHeaders(),
    params: {
      areaCode: ""
    },
    errorHandler: error => {
      message.error(error.data.message ?? error.message);
    }
  });
}

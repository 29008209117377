export default {
  "reserve-history.order-number": "Order Number",
  "reserve-history.order-endTime": "Appointment End Time",
  "reserve-history.order-startTime": "Appointment Start Time",
  "reserve-history.no-booking": "Number of Booking",
  "reserve-history.battery-cabinet": "Battery Cabinet",
  "reserve-history.reservation-status": "Reservation Status",
  "reserve-history.error": "Error",
  "reserve-history.progress": "Appointment",
  "reserve-history.cancel": "Cancel reservation",
  "reserve-history.complete": "Completed",
  "reserve-history.username": "Username",
  "reserve-history.phoneNumber": "Phone Number",
  "reserve-history.order-time": "Order Time",
  "reserve-history.order-information": "Order Information",
  "reserve-history.appointment-time": "Appointment Time (seconds)"
};

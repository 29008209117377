export default {
  "merchant-list.merchant-name": "Merchant Name",
  "merchant-list.merchant-type": "Merchant Type",
  "merchant-list.pic": "Person in charge",
  "merchant-list.mobile": "Contact number",
  "merchant-list.no-of-station": "Number of Integrated Stations",
  "merchant-list.no-of-swap-battery": "Number of Battery Swapping Stations",
  "merchant-list.status": "Distribution state",
  "merchant-list.merchant-type.agent": "Agent",
  "merchant-list.merchant-type.oyika": "Run directly by Oyika",
  "merchant-list.detail.essential-information": "Essential Information",
  "merchant-list.full-name": "Full name",
  "merchant-list.gender": "Gender",
  "merchant-list.id-number": "ID number",
  "merchant-list.id-card-front": "Front of ID card",
  "merchant-list.id-card-back": "Back of ID card",
  "merchant-list.merchant-id": "Merchant ID",
  "merchant-list.commission": "Commission",
  "merchant-list.office-address": "Office address",
  "merchant-list.detail.commission": "Commission",
  "merchant-list.edit": "Editor Merchant",
  "merchant-list.real-name": "Real name",
  "merchant-list.photo": "Photo",
  "merchant-list.add": "Add New"
};

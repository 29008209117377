export default {
  "operation.price.vehicle.vehicleBrand": "Vehicle Brand",
  "operation.price.vehicle.vehicleType": "Vehicle Type",
  "operation.price.vehicle.vehicleCompartmentType": "Vehicle Compartment Type",
  "operation.price.vehicle.doubleBattery": "Double Battery",
  "operation.price.vehicle.singleBattery": "Single Battery",
  "operation.price.vehicle.transactionType": "Transaction Type",
  "operation.price.vehicle.couldLease": "For Rent",
  "operation.price.vehicle.couldSell": "For Sale",
  "operation.price.vehicle.couldNotLeaseOrSell": "Not For Sale or Rent",
  "operation.price.vehicle.couldLeaseAndSell": "For Sale, For Rent",
  "operation.price.vehicle.saleOnly": "Sale Only",
  "operation.price.vehicle.leaseOnly": "Rent Only",
  "operation.price.vehicle.saleAndLease": "Rentable for Sale",
  "operation.price.vehicle.notForSaleOrRent": "Not for rent not for sale",
  // vehicle price details
  "operation.price.vehicle.detail": "Vehicle Price Details",
  "operation.price.vehicle.time": "Time"
};

export default {
  // Battery Type
  "battery.type.searchTable.title": "Battery Type List",
  "battery.type.typeName": "Type Name",
  "battery.type.batteryBrand": "Battery Brand",
  "battery.type.batteryType": "Battery Type",
  "battery.type.batteryStructure": "Battery Structure",
  "battery.type.numberCells": "Number of Cells",
  "battery.type.cellBrand": "Cell Brand",
  "battery.type.bmsBrand": "BMS Brand",
  "battery.type.ratedVoltage": "Rated Voltage (V)",
  "battery.type.ratedCurrent": "Rated Current (A)",
  "battery.type.ratedCapacity": "Rated Capacity (Ah)",
  "battery.type.weight": "Weight (KG)",
  "battery.type.typeOfCommunication": "Communication Type",
  "battery.type.usageState": "Usage State",
  "battery.type.tlb": "Ternary lithium battery",
  "battery.type.lfp": "Lithium iron phosphate battery",
  "battery.type.battery-details": "Battery Type Details",
  "battery.type.new-type": "Add Battery Type",
  "battery.type.new-battery-type": "New Battery Type",
  "battery.type.edit-battery-type": "Edit Battery Type",
  "battery.type.new-success": 'New Success',
  "battery.type.update-success": 'Update Success',
  "battery.type.batt-img": 'Battery Image',

  // Common
  "battery.common.ethernet": "Ethernet",
  "battery.common.all": "All",
  "battery.common.update-success": "Update Success",
  "battery.common.detail": "Detail",
  "battery.common.edit": "Edit",

  // Error
  "battery.error.empty": "Parameter cannot be empty",
  "battery.error.supported": "Chinese, letters, numbers, underline and minus are supported, the length limit is 1-20",
};

export default {
  // Cabinet List
  'cabinet.cabinet-code': 'Cabinet Number',
  'cabinet.cabinet-name': 'Cabinet Name',
  'cabinet.apk': 'Cabinet Apk Version',
  'cabinet.site-name': 'Station Name',
  'cabinet.serial-no': 'Android Serial No.',
  'cabinet.running-state': 'Running State',
  'cabinet.online-status': 'Status',
  'cabinet.cabin-amount': 'Amount of Cabin',
  'cabinet.soc': 'Swappable SOC',
  'cabinet.detail': 'Detail',
  'cabinet.details': 'Details',
  'cabinet.logs': 'Logs',
  'cabinet.soc-edit': 'Edit swappable SOC',
  'cabinet.real-time': 'Real Time Information',
  'cabinet.view-info': 'View Informations',
  'cabinet.batch': 'Batch Modify',
  'cabinet.batch.set_pin': 'Batch reset Ops PIN',
  'cabinet.batch.restart': 'Batch remote restart',
  'cabinet.disable': 'Disable Cabinet',
  'cabinet.enable': 'Enable Cabinet',
  'cabinet.restart': 'Restart Cabinet',
  'cabinet.total-battery': 'Total Battery Count',
  'cabinet.batteries-charging': 'Charging Box Count',
  'cabinet.empty-box': 'Idle Box Count',
  'cabinet.available-battery': 'Swappable Battery Count',
  'cabinet.reserve-battery': 'Reserve Battery Count',
  'cabinet.fault-box': 'Fault Box Count',
  'cabinet.disabled-box-count': 'Disabled Box Count',
  'cabinet.status': 'Cabinet Status',
  'cabinet.light-indicator': 'Legend',
  'cabinet.full-elec': 'Box with Swappable Battery',
  'cabinet.fault': 'Faulty box',
  'cabinet.charging': 'Box Charging',
  'cabinet.empty': 'Empty Box',
  'cabinet.idling': 'Box Idling',
  'cabinet.location': 'Cabinet Location',
  'cabinet.data': 'Cabinet Data',
  'cabinet.light-status': 'Light Status',
  'cabinet.fan-status': 'Fan Status',
  'cabinet.power-status': 'Power Status',
  'cabinet.online': 'Online',
  'cabinet.offline': 'Offline',
  'cabinet.idle': 'Idle',
  'cabinet.return': 'Return',
  'cabinet.running': 'Functioning',
  'cabinet.date': 'Date',
  'cabinet.file-name': 'File Name',
  'cabinet.size': 'Size',
  'cabinet.download-log': 'Download Current Logs',
  'cabinet.batch.soc': 'Batch edit swappable SOC',
  'cabinet.soc-column-title': 'Swappable SOC Percentage',
  'cabinet.soc-edit-title': 'Edit Swappable SOC percentage',
  'cabinet.soc-batch-edit-title': 'Batch edit swappable SOC',
  'cabinet.soc-edit-cabinet-number-field': 'Cabinet Number',
  'cabinet.box-info': 'Cabinet Box Information',
  'cabinet.box-condi': 'Box Condition',
  'cabinet.battery-status': 'Battery Status',
  'cabinet.charger-status': 'Charger Status',
  'cabinet.box-num': 'Box Number',
  'cabinet.indi-light': 'Indicator Light',
  'cabinet.cabin-failure': 'Cabinet Failure',
  'cabinet.in-place-detect': 'In Place detection',
  'cabinet.box-door-lock': 'Box door lock',
  'cabinet.disabled-box': 'Disabled box',
  'cabinet.disabled-box-desc': 'Temporary disable this cabinet box.',
  'cabinet.battery-num': 'Battery Number',
  'cabinet.view-details': 'View details',
  'cabinet.char-status': 'Charger Status',
  'cabinet.fault-condi': 'Fault Condition',
  'cabinet.curr-elec': 'Current Electricity',
  'cabinet.char-vol': 'Charger Voltage',
  'cabinet.char-current': 'Charging Current',
  'cabinet.char-power': 'Charging Power',
  'cabinet.bms-tmp': 'BMS Temperature',
  'cabinet.cell-tmp': 'Cell Temperature',
  'cabinet.char-preset': 'Charging Preset',
  'cabinet.open-box': 'Open Cabinet Box',
  'cabinet.back': 'Back',
  'cabinet.by-vehicle': 'By Vehicle Number',
  'cabinet.by-pin': 'By PIN Code',
  'cabinet.by-veh-desc': 'This login is mainly for updating of battery that are not captured in backend system.',
  'cabinet.veh-num': 'Vehicle Number',
  'cabinet.placeholder.veh-num': 'Enter Vehicle Number',
  'cabinet.veh-num-invalid': 'Invalid vehicle number entered. Please enter a valid vehicle number and try again.',
  'cabinet.pin-code': 'Pin Code',
  'cabinet.placeholder.pin-code': 'Enter Pin Code',
  'cabinet.pin-code-invalid': 'Invalid PIN code entered. Please enter a valid PIN code and try again.',
  'cabinet.search': 'Search',
  'cabinet.door-open': 'Door Open',
  'cabinet.result-found': 'Result Found',
  'cabinet.bsa': 'Battery Swap System',
  'cabinet.fms': 'Fleet Management',
  'cabinet.update': 'Update',
  'cabinet.no-cabin-avai': 'No Cabinet Box Available',
  'cabinet.restart-title': 'Restart',
  'cabinet.restart-desc': 'Are you sure you want to restart this cabinet?',
  'cabinet.confirm': 'Confirm',
  'cabinet.restart-success': 'Cabinet Restarted Successfully',
  'cabinet.restart-failed': 'Restart Cabinet failed',
  'cabinet.disabled-title': 'Disable This Cabinet?',
  'cabinet.disabled-success': 'Cabinet Disabled',
  'cabinet.disabled-failed': 'Cabinet Disable Failed',
  'cabinet.enabled-title': 'Enable This Cabinet?',
  'cabinet.enabled-success': 'Cabinet Enabled',
  'cabinet.enabled-failed': 'Cabinet Enable Failed',
  'cabinet.disabled-box-title': 'Disable This Cabin?',
  'cabinet.disabled-box-success': 'Cabin Disabled',
  'cabinet.enabled-box-title': 'Enable This Cabin?',
  'cabinet.enabled-box-success': 'Cabin Enabled',
  'cabinet.fault.none': 'No Data Available',
  'cabinet.fault.box-door-read-err': 'Cabin door failure',
  'cabinet.fault.open-door-failed': 'Failed to open the door',
  'cabinet.fault.read-door-status-failed': 'Failed to read the status of the door',
  'cabinet.fault.battery-box-open': 'The door of the battery compartment is open',
  'cabinet.fault.battery-bound': 'Battery bound',
  'cabinet.fault.check-battery-fail': 'Battery test failed',
  'cabinet.fault.battery-returning': 'Battery is being returned, not usable',
  'cabinet.fault.battery-not-in-site': 'Batteries are not assigned to the site and are not available',
  'cabinet.fault.bcb-fail': 'Failed to initialize slot',
  'cabinet.fault.battery-fault': 'Battery failure',
  'cabinet.fault.battery-not-exist': 'Battery does not exist',
  'cabinet.fault.read-battery-fail': 'Failed to read battery ID',
  'cabinet.fault.set-bin-fail': 'Failed to set the bin ID',
  'cabinet.fault.set-battery-fail': 'Failed to set battery BMS status',
  'cabinet.fault.query-battery-fail': 'Failed to query battery status',
  'cabinet.fault.box-status-unknown': 'The detection state is in place to obtain the exception',
  'cabinet.fault.box-battery-no': 'Battery detection in place, BCB can not read the battery',
  'cabinet.fault.box-battery-have': 'In place to detect no battery, BCB reads the battery',
  'cabinet.fault.set-battery-away-fail': 'Failed to set battery removable',
  'cabinet.fault.unknown-error': 'Unknown error',
  'cabinet.goods.in-place': 'Have in place',
  'cabinet.goods.not-reach-position': 'Not reach the designated position',
  'cabinet.battery.nothing': 'Nothing',
  'cabinet.battery.charging': 'Charging',
  'cabinet.battery.uncharged': 'Uncharged',
  'cabinet.battery.shelve': 'Shelve',
  'cabinet.no-light': 'No Light',
  'cabinet.green': 'Green',
  'cabinet.orange': 'Orange',
  'cabinet.blink-orange': 'Blinking Orange',
  'cabinet.info': 'Cabinet Information',
  'cabinet.humidity': 'Humidity',
  'cabinet.temp': 'Temperature',
  'cabinet.voltage': 'Voltage',
  'cabinet.current': 'Current',
  'cabinet.meter': 'Meter Reading',
  'cabinet.atmospheric': 'Atmospheric Condition',
  'cabinet.electronic': 'Electronic Conditions',
  'cabinet.hardware-status': 'Hardware Status',
  'cabinet.remarks': 'Remarks',
  'cabinet.deploymeny-status': 'Deployment Status',
  "cabinet.deployed": "Deployed",
  "cabinet.maintenance": "Maintenance",
  "cabinet.ready": "Ready to Deployment",
  'cabinet.agent': 'Agent',
  "cabinet.edit-remark": "Edit Remark",
  "cabinet.edit-status": "Edit Status",
  'cabinet.alarm': 'Alarm',
  "cabinet.curr-alarm": "Current Alarm",
  "cabinet.alarm-rec": "Alarm Record",
  "cabinet.open-box-door": "Open Box Door",
  "cabinet.nfc-status": "NFC Status",
  "cabinet.network-type": "Network Type",
  "cabinet.sig-intensity": "Signal Intensity",
  "cabinet.connect-condi": "Connectivity Conditions",
  "cabinet.main-cabinet-lamp": "Main Cabinet Lamp",
  "cabinet.off": "OFF",
  "cabinet.on": "ON",
  "cabinet.power-supply": "Power Supply State",
  "cabinet.total-batt-char": "Total Power Of Battery Charging",
  "cabinet.total-power-char": "Total Power Of Charger",
  "cabinet.normal": "Normal",
  "cabinet.abnormal": "Abnormal",
  "cabinet.clear-desc": "Confirm to clear?",

  //Cabinet Inventory
  'cabinet.stock.cabinet-type': 'Cabinet Type',
  'cabinet.stock.comm-type': 'Communication Type',
  'cabinet.stock.inv-status': 'Inventory Status',
  'cabinet.stock.out-stock': 'Out of stock',
  'cabinet.stock.in-stock': 'In stock',
  'cabinet.stock.hotline-settings': 'Hotline Settings',
  'cabinet.stock.hotline': 'Hotline',
  'cabinet.stock.wa': 'WA',
  'cabinet.stock.stock-in-out-record': 'Inventory Record',
  'cabinet.stock.new': 'Add New',
  'cabinet.stock.import': 'Import',
  'cabinet.stock.export': 'Export to Distribution',
  'cabinet.stock.new-success': 'New Success',
  'cabinet.stock.update-success': 'Update Success',
  'cabinet.stock.date-product': 'Date of Production',
  'cabinet.stock.edit-inv': 'Editorial Inventory',
  'cabinet.stock.cabinet-brand': 'Cabinet Brand',
  'cabinet.stock.cabinet-details': 'Details of electric cabinets',
  'cabinet.stock.soft-ver': 'Software Version',
  'cabinet.stock.hard-ver': 'Hardware Version',
  'cabinet.stock.rate-vol': 'Rated Voltage',
  'cabinet.stock.rate-curr': 'Rated Current',
  'cabinet.stock.download-template': 'Download The Template',
  'cabinet.stock.upload-list': 'Upload the list',
  'cabinet.stock.import-desc': 'The quantity added to the inventory this time: {quantity}',
  'cabinet.stock.export-desc': 'The quantity of this delivery: {quantity}',
  'cabinet.stock.success-export': 'Successful export',
  'cabinet.stock.upgrade-title': 'Equipment Upgrades',
  'cabinet.stock.upgrade-type': 'Upgrade type',
  'cabinet.stock.choose-door': 'Choose the doors',
  'cabinet.stock.version': 'Version',
  'cabinet.stock.upgrade': 'Upgrade',


  //Cabinet Common
  'cabinet.common.batch': 'Batch',
  'cabinet.common.date': 'Date',
  'cabinet.common.operation': 'operation',
  'cabinet.common.number': 'Number',
  'cabinet.common.operator': 'Operator',
  'cabinet.common.disabled': 'Disabled',
  'cabinet.common.ethernet': 'Ethernet',
  'cabinet.common.all': 'All',
  'cabinet.common.new': 'New',
  'cabinet.common.edit': 'Edit',
  'cabinet.common.delete': 'Delete',
  'cabinet.common.delete-desc': 'Are you sure to delete this item?',
  'cabinet.common.remind': 'Remind',
  'cabinet.common.success-operation': 'Successful operation',
  'cabinet.common.tip': 'Tip',
  'cabinet.common.cancel': 'Cancel',
  'cabinet.common.action': 'Action',
  'cabinet.common.add': 'Add',
  'cabinet.common.gen2-cabinet': 'Gen2 Cabinet',
  'cabinet.common.gen3-cabinet': 'Gen3 Cabinet',
  'cabinet.common.clear': 'Clear',
  'cabinet.common.unconnected': 'Unconnected',
  'cabinet.common.connected': 'Connected',

  //Cabinet Error
  'cabinet.error.empty': 'Parameter cannot be empty',
  'cabinet.error.length-20': 'Letters and numbers are supported, the length limit is 1~20',
  "cabinet.error.supported": "Chinese, letters, numbers, underline and minus are supported, the length limit is 1-20",
  'cabinet.error.no-data': 'No Data Available',
  'cabinet.error.no-file': 'No File Uploaded',
  'cabinet.error.please-check': 'Please check the cabinet in the form before delivery!',
  'cabinet.error.cabinet-disabled': 'This Cabinet is currently disabled',
  "cabinet.error.no-sig": "No Signal",
};

export default {
	'swap-station-list': 'Swap Station List',
	'swap-station-list.swap-station-name': 'Station Name',
	'swap-station-list.swap-station-province-code': 'Province',
	'swap-station-list.swap-station-city-code': 'City',
	'swap-station-list.swap-station-address': 'Address',
	'swap-station-list.swap-station-leader': 'P.I.C',
	'swap-station-list.swap-station-mobile': 'P.I.C. Mobile No.',
	'swap-station-list.swap-station-cabinet-number': 'Amount Of Cabinets',
	'swap-station-list.status': 'Usage state',
	'swap-station-list.edit': 'Edit Swap Station',
	'swap-station-list.add': 'Add New Station',
	'swap-station-list.details': 'Station Details',
	'swap-station-details.station-inf': 'Battery swapping station information',
	'swap-station-details.identity': 'Site properties',
	'swap-station-details.name': 'Site name',
	'swap-station-details.merchant.name': 'Site assignment',
	'swap-station-details.merchant.type': 'Site type',
	'swap-station-details.status': 'Current state',
	'swap-station-details.create-time': 'Create time',
	'swap-station-details.business-hours': 'Business hours',
	'swap-station-details.cabinet-code': 'Cabinet information',
	'swap-station-details.address': 'Site address',
	'swap-station-details.identity.SITE_STORE': 'Integrated station',
	'swap-station-details.identity.SITE_CABINET': 'Battery swapping station',
	'swap-station-details.tabs.site-leader': 'Site leader',
	'swap-station-details.tabs.battery-cabinet': 'Site cabinet',
	'swap-station-details.full-name': 'Full name',
	'swap-station-details.user-id': 'User ID',
	'swap-station-details.gender': 'Gender',
	'swap-station-details.merchant.gender.is-wechat': 'WeChat Login',
	'swap-station-details.mobile': 'Mobile',
	'swap-station-details.idcard': 'ID number',
	'swap-station-details.enclosurePhoto': 'Appendices of a contract',
	'swap-station-form.essential-inf': 'Essential information',
	'swap-station-form.business-start-time': 'Business start time',
	'swap-station-form.business-end-time': 'Business end time',
	'swap-station-form.province': 'Province',
	'swap-station-form.city': 'City',
	'swap-station-form.address': 'Address',
	'swap-station-form.leader': 'Leader',
}

import type { Settings as LayoutSettings } from "@ant-design/pro-layout";
import { PageLoading } from "@ant-design/pro-layout";
import type { RequestConfig, RunTimeLayoutConfig } from "umi";
import { history } from "umi";
import RightContent from "@/components/RightContent";
import Footer from "@/components/Footer";
import { currentUser as queryCurrentUser } from "./services/ant-design-pro/api";
import { getCurrencyDetail } from "./services/ant-design-pro/api-currency";
// import { BookOutlined, LinkOutlined } from '@ant-design/icons';
import oyikaLogo from "../public/Oyika_logo.png";
import { getHeaders } from "./services/ant-design-pro/api";
import { message } from "antd";
import Header from "./components/Header";

// const isDev = process.env.NODE_ENV === 'development';
const loginPath = "/user/login";

/** 获取用户信息比较慢的时候会展示一个 loading */
export const initialStateConfig = {
  loading: <PageLoading />
};

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  loading?: boolean;
  settings?: Partial<LayoutSettings>;
  currentUser?: API.CurrentUser;
  fetchUserInfo?: () => Promise<API.CurrentUser | undefined>;
}> {
  const fetchUserInfo = async () => {
    try {
      const msg = await queryCurrentUser();
      const currencyRes = await getCurrencyDetail();
      console.log(currencyRes, ">>> to here");
      if (currencyRes) {
        localStorage.setItem("currency", currencyRes.data.currency);
      } else {
        localStorage.removeItem("currency");
      }
      return msg["data"];
    } catch (error) {
      history.push(loginPath);
    }
    return undefined;
  };
  // 如果是登录页面，不执行
  if (history.location.pathname !== loginPath) {
    const currentUser = await fetchUserInfo();
    return {
      fetchUserInfo,
      currentUser,
      settings: {}
    };
  }
  return {
    fetchUserInfo,
    settings: {}
  };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState }) => {
  return {
    disableContentMargin: false,
    headerRender: props => <Header {...props} />,
    footerRender: () => <Footer />,
    onPageChange: () => {
      const { location } = history;
      // 如果没有登录，重定向到 login
      // if (!initialState?.currentUser && location.pathname !== loginPath) {
      //   history.push(loginPath);
      // }
      if (!getHeaders && location.pathname !== loginPath) {
        history.push(loginPath);
      }
    },
    menuHeaderRender: undefined,
    childrenRender: children => {
      if (initialState?.loading) return <PageLoading />;
      return <>{children}</>;
    },
    // 自定义 403 页面
    // unAccessible: <div>unAccessible</div>,
    ...initialState?.settings
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const responseInterceptors = (response: Response, options: any) => {
  // response.headers.append('interceptors', 'yes yo');
  // console.log('responseInterceptors', response);
  if (response.status === 401) {
    console.log("responseInterceptors 401", response);
    history.push(loginPath);
    return response;
  }

  if (response.status < 200 || response.status >= 300) {
    console.log("responseInterceptors", response);
    // message.error('Error ' + response.status + ': ' + response.statusText);
    // throw new Error(response.statusText);
  }

  return response;
};

export const request: RequestConfig = {
  // errorHandler,
  responseInterceptors: [responseInterceptors]
};

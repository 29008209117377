export default {
  "cabinet-type.brand-name": "Cabinet Brand",
  "cabinet-type.model": "Cabinet Type",
  "cabinet-type.type-name": "Type Name",
  "cabinet-type.box-count": "Amount of Cabin",
  "cabinet-type.network-type": "Communication Type",
  "cabinet-type.rated-power": "Cabinet load (W)",
  "cabinet-type.rated-voltage": "Rated voltage (V)",
  "cabinet-type.rated-current": "Rated current (A)",
  "cabinet-type.enable-status": "Usage state",
  "cabinet-type.add-form-title": "New Type of Electric Cabinet",
  "cabinet-type.edit-form-title": "Edit Cabinet Type",
  "cabinet-type.detail": "Detail",
  "cabinet-type.edit": "Edit",
  "cabinet-type.new": "Add Cabinet Type",
  "cabinet-type.detail-title": "Cabinet Type Details",
};

export default {
	'area-list.area-code': 'Area Code',
	'area-list.area-name': 'Area Name',
	'area-list.parent-area': 'Parent Area',
	'area-list.area-type': 'Area Type',
	'area-list.area-polygon': 'Area Polygon',
	'area-list.status': 'Usage state',
	'area-list.area-type.province': 'Province',
	'area-list.area-type.city': 'City',
	'area-list.create': 'Add New',
	'area-list.map': 'View Map',
	'area-list.edit': 'Edit Area',
}

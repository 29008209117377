export default {
  "users.user": "User",
  "users.user-id": "User ID",
  "users.cell-phone": "Mobile No.",
  "users.real-name": "Real Name",
  "users.cert-no": "Certificate No.",
  "users.cert-type": "Certificate Type",
  "users.auth-status": "Authentication Status",
  "users.audit-mode": "Audit Mode",
  "users.is-it-due": "Due Status",
  "users.ext-system": "External System",
  "users.usage-state": "Usage Status",
  "users.restricted-status": "Restricted Status",
  "users.certified": "Certified",
  "users.uncertified": "Uncertified",
  "users.to-audit": "To Audit",
  "users.yes": "Yes",
  "users.no": "No",
  "users.enabled": "Enabled",
  "users.disabled": "Disabled",
  "users.normal": "Normal",
  "users.abnormal": "Abnormal",
  "users.fault": "Fault",
  "users.unrestricted": "Unrestricted",
  "users.auto-audit": "Automatic Audit",
  "users.manual-audit": "Manual Audit",
  "users.agreement": "Agreement",
  "users.relieve": "Relieve",
  "users.relieve.desc": "Are you sure to lift restrictions?",
  "users.restoration": "Restoration",
  "users.restoration.desc": "Are you sure to restore restrictions?",
  "users.essen-info": "Essential information",
  "users.nickname": "Nick Name",
  "users.gender": "Gender",
  "users.regis-no": "Registration No.",
  "users.regis-time": "Registration Time",
  "users.face-recog": "Face Recognition",
  "users.user-wallet": "User Wallet",
  "users.battery-qty": "Battery Quantity",
  "users.auth-time": "Authentication Time",
  "users.front-card": "Front of ID Card",
  "users.back-card": "Back of ID Card",
  "users.id-card": "ID Card",
  "users.passport": "Passport",
  "users.face-recog-photo": "Face Recognition Photos",
  "users.remove-pack": "Remove Package",
  "users.male": "Male",
  "users.female": "Female",
  "users.click-view": "Click To View",
  "users.user-vehicle": "User Vehicle",
  "users.consumer-batt": "Consumer Battery",
  "users.user-appoint": "User Appointment",
  "users.user-swapping": "User Battery Swapping",
  "users.view-detail": "View Details",
  "users.view-path": "Viewing Path",
  "users.no-data": "No Data Available",
  "users.remove-desc": "Remove Plan?",
  "users.state": "State",
  "users.user-name": "User Name",
  "users.given-name": "Given Name",
  "users.family-name": "Family Name",
  "users.upload-image": "Upload Image",
  "users.pass": "Pass",
  "users.save": "Save",
  "users.cancel": "Cancel",
  "users.download-agreement": "Download Agreement",
  "users.no-agreement-found": "No Agreement Found",

  // Vehicle
  "users.veh-info": "Vehicle Information",
  "users.vcu-no": "VCU Number",
  "users.veh-name": "Vehicle Name",
  "users.veh-type": "Vehicle Type",
  "users.business-status": "Business Status",
  "users.acc-switch": "ACC Switch",
  "users.active-state": "Activation State",
  "users.active-time": "Activation Time",
  "users.distributor": "Distributor",
  "users.due-date": "Due Date",
  "users.idle": "Idle",
  "users.sell": "Sell",
  "users.lease": "Lease",
  "users.no-active": "Not Active",
  "users.activated": "Activated",
  "users.single-batt": "Single Battery",
  "users.double-batt": "Double Battery",

  // Battery
  "users.first-batt": "First Battery",
  "users.second-batt": "Second Battery",
  "users.batt-name": "Battery Name",
  "users.batt-no": "Battery Number",
  "users.status": "Status",
  "users.batt-type": "Battery Type",
  "users.business-type": "Business Type",
  "users.freq-use": "Frequency of Use",
  "users.date-production": "Date of Production",
  "users.curr-elec": "Current Electricity",
  "users.batt-status": "Battery Status",
  "users.curr-voltage": "Current Voltage",
  "users.dischar-voltage": "Discharge Current",
  "users.bms-temp": "BMS Temperature",
  "users.cell-temp": "Cell Temperature",
  "users.fault-condi": "Fault Condition",
  "users.online": "Online",
  "users.offline": "Offline",
  "users.appoint-lock": "Appointment lockout",
  "users.battery-lock": "Battery swapping lock",
  "users.shelved": "Shelve",
  "users.discharging": "Discharging",
  "users.charging": "Charging",
  "users.cycle-time": "{cycle} Time",
  "users.list": "User List",
  "users.audit": "User Audit",
};

export default {
  //Battery Inventory
  "battery.inventory.searchTable.title": "Battery Inventory List",
  "battery.inventory.batteryNumber": "Battery Number",
  "battery.inventory.batteryName": "Battery Name",
  "battery.inventory.imei": "IMEI",
  "battery.inventory.batteryType": "Battery Type",
  "battery.inventory.onlineStatus": "Status",
  "battery.inventory.electricity": "Current Electricity",
  "battery.inventory.inventoryStatus": "Inventory Status",
  "battery.inventory.batteryBrand": "Battery Brand",
  "battery.inventory.model": "Battery Type",
  "battery.inventory.dateProduct": "Date of Production",
  "battery.inventory.bmsBrand": "BMS brand",
  "battery.inventory.coreBrand": "Cell brand",
  "battery.inventory.cellCount": "Number of cells",
  "battery.inventory.softVersion": "Software version",
  "battery.inventory.hardwareVersion": "Hardware version",
  "battery.inventory.ratedCurrent": "Rated current",
  "battery.inventory.ratedVoltage": "Rated voltage",
  "battery.inventory.ratedCapacity": "Rated capacity",
  "battery.inventory.structure": "Battery structure",
  "battery.inventory.networkType": "Type of communication",
  "battery.inventory.weight": "Weight",
  "battery.inventory.batteryDetails": "Battery details",
  "battery.online": "Online",
  "battery.offline": "Offline",
  "battery.stock.out-stock": "Out of stock",
  "battery.stock.in-stock": "In stock",
  "battery.stock.stock-in-out-record": "Inventory Record",
  "battery.stock.inventory-record": "Inventory Record",
  "battery.stock.new": "Add New",
  "battery.stock.import": "Import",
  "battery.stock.export": "Export to Distribution",
  "battery.stock.new-success": "New Success",
  "battery.stock.update-success": "Update Success",
  "battery.stock.batteryDetails": "Battery Details",
  "battery.error.no-file": "No File Uploaded",
  "battery.common.cancel": "Cancel",
  "battery.stock.upload-list": "Upload the list",
  "battery.stock.download-template": "Download The Template",
  "battery.confirm": "Confirm",
  "battery.stock.edit-inv": "Editorial Inventory",
  "battery.common.batch": "Batch",
  "battery.common.date": "Date",
  "battery.common.operation": "operation",
  "battery.common.number": "Number",
  "battery.common.operator": "Operator",
  "battery.common.disabled": "Disabled",
  "battery.common.ethernet": "Ethernet",
  "battery.common.all": "All",
  "battery.common.new": "New",
  "battery.common.edit": "Edit",
  "battery.common.delete": "Delete",
  "battery.common.battery-type": "Battery Type",
  "battery-inventory.common.detail": "Details",
  "battery.common.delete-desc": "Are you sure to delete this item?",
  "battery.common.remind": "Remind",
  "battery.common.success-operation": "Successful operation",
  "battery.common.tip": "Tip",
  "battery-inventory.stock.export-desc":
    "Are you sure you want to export {quantity} batteries?",
  "battery.error.empty": "Parameter cannot be empty",
  "battery.error.length-20": "Letters and numbers are supported, the length limit is 1~20",
  "battery.error.supported": "Chinese, letters, numbers, underline and minus are supported, the length limit is 1-20",
  "battery.error.no-data": "No Data Available",
  "battery.error.please-check": "Please check the battery in the form before delivery!",
  'battery.stock.success-export': 'Successful export',
};

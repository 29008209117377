export default {
  "menu.welcome": "Welcome",
  "menu.more-blocks": "More Blocks",
  "menu.home": "Home",
  "menu.admin": "Admin",
  "menu.admin.sub-page": "Sub-Page",
  "menu.login": "Login",
  "menu.register": "Register",
  "menu.register-result": "Register Result",
  "menu.dashboard": "Dashboard",
  "menu.dashboard.analysis": "Analysis",
  "menu.dashboard.monitor": "Monitor",
  "menu.dashboard.workplace": "Workplace",
  "menu.exception.403": "403",
  "menu.exception.404": "404",
  "menu.exception.500": "500",
  "menu.form": "Form",
  "menu.form.basic-form": "Basic Form",
  "menu.form.step-form": "Step Form",
  "menu.form.step-form.info": "Step Form(write transfer information)",
  "menu.form.step-form.confirm": "Step Form(confirm transfer information)",
  "menu.form.step-form.result": "Step Form(finished)",
  "menu.form.advanced-form": "Advanced Form",
  "menu.list": "List",
  "menu.list.user-list": "Users",
  "menu.list.table-list": "Search Table",
  "menu.list.basic-list": "Basic List",
  "menu.list.card-list": "Card List",
  "menu.list.search-list": "Search List",
  "menu.list.search-list.articles": "Search List(articles)",
  "menu.list.search-list.projects": "Search List(projects)",
  "menu.list.search-list.applications": "Search List(applications)",
  "menu.profile": "Profile",
  "menu.profile.basic": "Basic Profile",
  "menu.profile.advanced": "Advanced Profile",
  "menu.result": "Result",
  "menu.result.success": "Success",
  "menu.result.fail": "Fail",
  "menu.exception": "Exception",
  "menu.exception.not-permission": "403",
  "menu.exception.not-find": "404",
  "menu.exception.server-error": "500",
  "menu.exception.trigger": "Trigger",
  "menu.account": "Account",
  "menu.account.center": "Account Center",
  "menu.account.settings": "Account Settings",
  "menu.account.trigger": "Trigger Error",
  "menu.account.logout": "Logout",
  "menu.editor": "Graphic Editor",
  "menu.editor.flow": "Flow Editor",
  "menu.editor.mind": "Mind Editor",
  "menu.editor.koni": "Koni Editor",
  "menu.Roles": "Roles",
  "menu.payment-log": "Payment Log",
  "menu.payment-transaction": "Payment Transaction",
  "menu.Report": "Report",
  //'menu.Report.payment-popularity': 'Payment Popularity',
  //'menu.Report.profit-missed': 'Profit Missed',
  "menu.payment-popularity": "Payment Popularity",
  "menu.profit-missed": "Profit Missed",
  "menu.Settings": "Settings",
  "menu.Settings.setting-country": "Country",
  "menu.Settings.setting-currency": "Currency",
  "menu.Settings.setting-merchant": "Merchant",
  "menu.Settings.setting-merchant-category": "Merchant Category",
  "menu.Settings.setting-systemconfig": "System Config",
  "menu.Settings.payment-gateway": "Payment Gateway",
  "menu.Settings.payment-config": "Payment Config",
  "menu.Settings.payment-option": "Payment Option",
  "menu.Settings.payment-typeDisplay": "Payment Type Display",
  "menu.Settings.setting-CustomerSupport": "Customer Support",
  "menu.change-password": "Change Password",
  "menu.list.batteries": "Batteries",
  "menu.list.batteries.batteries-list": "Batteries List",
  "menu.list.batteries.battery-inventory": "Battery Inventory",
  "menu.list.batteries.battery-type": "Battery Type",
  // Order Centre
  "menu.list.orders": "Order Centre",
  "menu.list.orders.order-management": "Order Management",
  "menu.list.orders.order-management.product-order": "Package Order",
  "menu.list.orders.order-management.product-order-detail":
    "Package Order Detail",
  "menu.list.orders.order-management.vehicle-order": "Vehicle Order",
  "menu.list.orders.order-management.vehicle-order-detail":
    "Vehicle Order Detail",
  "menu.list.orders.order-management.battery-order": "Battery Order",
  "menu.list.orders.order-management.battery-order-detail":
    "Battery Order Detail",
  "menu.list.orders.order-management.swapping-battery-order":
    "Swapping Battery Order",
  "menu.list.orders.order-management.swapping-battery-order-detail":
    "Swapping Battery Order Detail",
  "menu.list.orders.order-management.per-kwh-order": "Per Per kWh",
  // Menu for Product Centre
  "menu.list.product-center": "Product Centre",
  // Vehicle
  "menu.list.product-center.vehicle": "Vehicle",
  "menu.list.product-center.vehicle.type": "Type",
  "menu.list.product-center.vehicle.inventory": "Inventory",
  "menu.list.product-center.vehicle.list": "List",
  "menu.list.product-center.vehicle.distribution": "Distribution",
  "menu.list.product-center.vehicle.vehicle-stock-record": "Inventory Record",
  "menu.list.product-center.vehicle.vehicle-stock-record-detail": "Details",
  "menu.list.product-center.vehicle.vehicle-detail": "Vehicle Detail",
  "menu.list.product-center.vehicle.vehicle-trajectory": "Vehicle Route Map",
  // Battery
  "menu.list.product-center.battery": "Battery",
  "menu.list.product-center.battery.type": "Type",
  "menu.list.product-center.battery.inventory": "Inventory",
  "menu.list.product-center.battery.list": "List",
  "menu.list.product-center.battery.distribution": "Distribution",
  "menu.list.product-center.battery.battery-inventory-record":
    "Inventory Record",
  "menu.list.product-center.battery.battery-inventory-record-detail": "Details",
  "menu.list.product-center.battery.battery-list-real":
    "Battery Real-Time Information",
  "menu.list.product-center.battery.battery-list-trajectory":
    "Battery Route Map",
  // Cabinet
  "menu.list.product-center.cabinet": "Cabinet",
  "menu.list.product-center.cabinet.type": "Type",
  "menu.list.product-center.cabinet.inventory": "Inventory",
  "menu.list.product-center.cabinet.list": "List",
  "menu.list.product-center.cabinet.distribution": "Distribution",
  "menu.list.product-center.cabinet.stock-record": "Inventory Record",
  "menu.list.product-center.cabinet.stock-record-detail": "Details",
  "menu.list.product-center.cabinet.cabinet-list-real":
    "Cabinet Real-Time Information",
  // System Centre
  "menu.list.system": "System Centre",
  "menu.list.system.audit-log": "Audit Log",
  "menu.list.system.system-management.admin-list": "Admin List",
  "menu.list.system.system-management.merchant-list": "Merchant List",
  "menu.list.system.system-management.merchant-detail": "Merchant Detail",
  "menu.list.system.system-management": "Settings",
  "menu.list.system.system-management.profile": "Profile",
  "menu.list.system.system-management.admin": "Admin",
  "menu.list.system.system-management.merchant": "Merchant",
  "menu.list.system.system-management.role": "Role",
  "menu.list.system.system-management.menu-index": "Menu Index",
  "menu.list.system.system-management.area-list": "Area List",
  "menu.list.system.system-management.currency": "Currency",
  // Upgrade Management
  "menu.list.system.upgrade-management": "Upgrade Management",
  "menu.list.system.upgrade-management.update-record": "Update Record",
  "menu.list.system.upgrade-management.version-management":
    "Version Management",
  // Operation Centre
  "menu.list.operation-center": "Operation Centre",
  // Swap Station
  "menu.list.operation-center.swap-station": "Swap Station",
  "menu.list.operation-center.swap-station-detail": "Swap Station Detail",
  "menu.list.operation-center.edit-swap-station": "Edit Swap Station",
  "menu.list.operation-center.create-swap-station": "Create Swap Station",
  // External Integration
  "menu.list.operation-center.external-integration": "External Integration",
  "menu.list.operation-center.external-integration.fleet-management":
    "Fleet Management",
  "menu.list.operation-center.external-integration.user-sso": "User SSO",
  "menu.list.operation-center.external-integration.user-detail": "User Detail",
  "menu.list.operation-center.external-integration.promo-engine":
    "Promo Engine",
  // Notification Management
  "menu.list.operation-center.notification-management":
    "Notification Management",
  "menu.list.operation-center.notification-management.notification":
    "Notification",
  // Price Management
  "menu.list.operation-center.price-management": "Price Management",
  "menu.list.operation-center.price-management.vehicle-price": "Vehicle Price",
  "menu.list.operation-center.price-management.product-price": "Package Price",
  // History
  "menu.list.operation-center.history": "History",
  "menu.list.operation-center.history.swap-history": "Swap History",
  "menu.list.operation-center.history.reserve-history": "Reservation History",
  "menu.list.operation-center.history.swap-history-detail":
    "Swap History Detail",
  "menu.list.operation-center.history.reserve-history-detail":
    "Reservation History Detail",
  "menu.list.operation-center.history.alarm-record":
    "Alarm History",
  // Return Assets
  "menu.list.operation-center.return": "Return Assets",
  "menu.list.operation-center.return-detail":
    "Details of After-Sales Application",
  // Dashboard
  "menu.list.dashboard": "Dashboard",
  "menu.list.dashboard.batt-offline": "Battery Offline",
  "menu.list.dashboard.batt-starvation": "Battery Starvation",
  "menu.list.dashboard.batt-char-outside": "Charging Outside Cabinet",
  "menu.list.dashboard.batt-wrong-loc": "Battery Wrong Location",
  "menu.list.dashboard.cabinet-offline": "Cabinet Offline",
  "menu.list.dashboard.cabinet-water": "Cabinet Water",
  "menu.list.dashboard.cabinet-smoke": "Cabinet Smoke",
  "menu.list.dashboard.cabinet-door-disabled": "Cabinet Door Disabled",
  "menu.list.dashboard.cabinet-door-not-char": "Cabinet Door Not Charging",
  "menu.list.dashboard.batt-temp-in-cabinet": "Battery Temp In Cabinet",
  "menu.list.dashboard.cabinet-high-temp": "Cabinet High Temp",
  "menu.list.dashboard.veh-offline": "Vehicle Offline",
  "menu.list.dashboard.veh-low-soc": "Vehicle Low SOC",
  "menu.list.dashboard.motor-controller-abnormal": "Motor Controller Abnormal",
};

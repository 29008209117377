export default {
  "swap-history.order-number": "Order Number",
  "swap-history.order-endTime": "Order End Time",
  "swap-history.no-battery-swap": "Number of battery swapping",
  "swap-history.battery-cabinet": "Battery Cabinet",
  "swap-history.battery-state": "Battery Swapping State",
  "swap-history.battery-mode": "Battery Swapping Mode",
  "swap-history.username": "Username",
  "swap-history.phoneNumber": "Cell-phone Number",
  "swap-history.details": "Details",
  "swap-history.progress": "Have in hand",
  "swap-history.cancel": "Cancelled",
  "swap-history.complete": "Completed",
  "swap-history.error": "abnormal",
  "swap-history.face": "Face",
  "swap-history.sweep-code": "Sweep Code",
  "swap-history.offline": "Off-line",
  "swap-history.gopay": "GOPAY",
  "swap-history.order-time": "Order Time",
  "swap-history.order-information": "Order Information",
  "swap-history.detail-number": "Detail Number",
  "swap-history.order-status": "Order Status",
  "swap-history.return-cabin-door": "Return Cabin Door",
  "swap-history.pick-up-door": "Pick-up Door",
  "swap-history.start-time": "Order Start Time",
  "swap-history.end-time": "Order End Time",
  "swap-history.power-consumption": "Power Consumption (s)",
  "swap-history.essential-information": "Essential Information",
  "swap-history.power-on-information": "Power On Information - {number}",
  "swap-history.power-return-information":
    "Power Return Information - {number}",
  "swap-history.battery-brand": "Battery Brand",
  "swap-history.battery-type": "Battery Type",
  "swap-history.battery-number": "Battery Number",
  "swap-history.battery-status": "Online Status",
  "swap-history.battery-quantity": "Electric Quantity"
};

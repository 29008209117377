import { request } from "umi";
import { getHeaders } from "./api";
import { message } from "antd";

export async function getCurrencyDetail() {
  return request<any>(`/api/v3/oms/currency/config/detail`, {
    method: "GET",
    headers: getHeaders(),
    errorHandler: error => {
      message.error(error.data.message ?? error.message);
    }
  }).then(res => {
    return {
      message: res.message,
      local: res.local,
      code: res.code,
      data: res.data
    };
  });
}

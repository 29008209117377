// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/app/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: '.umi__plugin-layout__Layout' */'/app/src/.umi/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/~demos/:uuid",
        "layout": false,
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'../dumi/layout'), loading: LoadingComponent})],
        "component": ((props) => dynamic({
          loader: async () => {
            const React = await import('react');
            const { default: getDemoRenderArgs } = await import(/* webpackChunkName: 'dumi_demos' */ '/app/node_modules/@umijs/preset-dumi/lib/plugins/features/demo/getDemoRenderArgs');
            const { default: Previewer } = await import(/* webpackChunkName: 'dumi_demos' */ 'dumi-theme-default/es/builtins/Previewer.js');
            const { usePrefersColor, context } = await import(/* webpackChunkName: 'dumi_demos' */ 'dumi/theme');

            return props => {
              
      const { demos } = React.useContext(context);
      const [renderArgs, setRenderArgs] = React.useState([]);

      // update render args when props changed
      React.useLayoutEffect(() => {
        setRenderArgs(getDemoRenderArgs(props, demos));
      }, [props.match.params.uuid, props.location.query.wrapper, props.location.query.capture]);

      // for listen prefers-color-schema media change in demo single route
      usePrefersColor();

      switch (renderArgs.length) {
        case 1:
          // render demo directly
          return renderArgs[0];

        case 2:
          // render demo with previewer
          return React.createElement(
            Previewer,
            renderArgs[0],
            renderArgs[1],
          );

        default:
          return `Demo ${props.match.params.uuid} not found :(`;
      }
    
            }
          },
          loading: () => null,
        }))()
      },
      {
        "path": "/_demos/:uuid",
        "redirect": "/~demos/:uuid"
      },
      {
        "__dumiRoot": true,
        "layout": false,
        "path": "/~docs",
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'../dumi/layout'), loading: LoadingComponent}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/app/node_modules/dumi-theme-default/es/layout.js'), loading: LoadingComponent})],
        "routes": [
          {
            "path": "/~docs",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'README.md' */'/app/README.md'), loading: LoadingComponent}),
            "exact": true,
            "meta": {
              "locale": "en-US",
              "order": null,
              "filePath": "README.md",
              "updatedTime": 1737712199000,
              "slugs": [
                {
                  "depth": 1,
                  "value": "Ant Design Pro",
                  "heading": "ant-design-pro"
                },
                {
                  "depth": 2,
                  "value": "5.0 is out! 🎉🎉🎉",
                  "heading": "50-is-out-"
                },
                {
                  "depth": 2,
                  "value": "Translation Recruitment :loudspeaker:",
                  "heading": "translation-recruitment-loudspeaker"
                },
                {
                  "depth": 2,
                  "value": "Features",
                  "heading": "features"
                },
                {
                  "depth": 2,
                  "value": "Templates",
                  "heading": "templates"
                },
                {
                  "depth": 2,
                  "value": "Usage",
                  "heading": "usage"
                },
                {
                  "depth": 3,
                  "value": "Use bash",
                  "heading": "use-bash"
                },
                {
                  "depth": 3,
                  "value": "Use Gitpod",
                  "heading": "use-gitpod"
                },
                {
                  "depth": 2,
                  "value": "Browsers support",
                  "heading": "browsers-support"
                },
                {
                  "depth": 2,
                  "value": "Contributing",
                  "heading": "contributing"
                }
              ],
              "title": "Ant Design Pro"
            },
            "title": "Ant Design Pro"
          },
          {
            "path": "/~docs/components",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__index.md' */'/app/src/components/index.md'), loading: LoadingComponent}),
            "exact": true,
            "meta": {
              "filePath": "src/components/index.md",
              "updatedTime": 1737712199000,
              "title": "业务组件",
              "sidemenu": false,
              "slugs": [
                {
                  "depth": 1,
                  "value": "业务组件",
                  "heading": "业务组件"
                },
                {
                  "depth": 2,
                  "value": "Footer 页脚组件",
                  "heading": "footer-页脚组件"
                },
                {
                  "depth": 2,
                  "value": "HeaderDropdown 头部下拉列表",
                  "heading": "headerdropdown-头部下拉列表"
                },
                {
                  "depth": 2,
                  "value": "HeaderSearch 头部搜索框",
                  "heading": "headersearch-头部搜索框"
                },
                {
                  "depth": 3,
                  "value": "API",
                  "heading": "api"
                },
                {
                  "depth": 2,
                  "value": "NoticeIcon 通知工具",
                  "heading": "noticeicon-通知工具"
                },
                {
                  "depth": 3,
                  "value": "NoticeIcon API",
                  "heading": "noticeicon-api"
                },
                {
                  "depth": 3,
                  "value": "NoticeIcon.Tab API",
                  "heading": "noticeicontab-api"
                },
                {
                  "depth": 3,
                  "value": "NoticeIconData",
                  "heading": "noticeicondata"
                },
                {
                  "depth": 2,
                  "value": "RightContent",
                  "heading": "rightcontent"
                }
              ],
              "hasPreviewer": true,
              "group": {
                "path": "/~docs/components",
                "title": "Components"
              }
            },
            "title": "业务组件 - ant-design-pro"
          }
        ],
        "title": "ant-design-pro",
        "component": (props) => props.children
      },
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user",
            "routes": [
              {
                "name": "login",
                "layout": false,
                "path": "/user/login",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Login__Login' */'/app/src/pages/Login/Login'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/app/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "list.dashboard",
        "path": "/dashboard",
        "icon": "dashboard",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard' */'/app/src/pages/Dashboard'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dashboard/batt-offline",
        "name": "list.dashboard.batt-offline",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__Battery__BatteryOffline' */'/app/src/pages/Dashboard/Battery/BatteryOffline'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/dashboard/batt-starvation",
        "name": "list.dashboard.batt-starvation",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__Battery__BatteryStarvation' */'/app/src/pages/Dashboard/Battery/BatteryStarvation'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/dashboard/batt-char-outside",
        "name": "list.dashboard.batt-char-outside",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__Battery__CharOutsideCabinet' */'/app/src/pages/Dashboard/Battery/CharOutsideCabinet'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/dashboard/batt-wrong-loc",
        "name": "list.dashboard.batt-wrong-loc",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__Battery__BatteryWrongLoc' */'/app/src/pages/Dashboard/Battery/BatteryWrongLoc'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/dashboard/cabinet-offline",
        "name": "list.dashboard.cabinet-offline",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__Cabinet__CabinetOffline' */'/app/src/pages/Dashboard/Cabinet/CabinetOffline'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/dashboard/cabinet-water",
        "name": "list.dashboard.cabinet-water",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__Cabinet__CabinetWater' */'/app/src/pages/Dashboard/Cabinet/CabinetWater'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/dashboard/cabinet-smoke",
        "name": "list.dashboard.cabinet-smoke",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__Cabinet__CabinetSmoke' */'/app/src/pages/Dashboard/Cabinet/CabinetSmoke'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/dashboard/cabinet-door-disabled",
        "name": "list.dashboard.cabinet-door-disabled",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__Cabinet__CabinetDoorDisabled' */'/app/src/pages/Dashboard/Cabinet/CabinetDoorDisabled'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/dashboard/cabinet-door-not-char",
        "name": "list.dashboard.cabinet-door-not-char",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__Cabinet__CabinetDoorNotCharging' */'/app/src/pages/Dashboard/Cabinet/CabinetDoorNotCharging'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/dashboard/batt-temp-in-cabinet",
        "name": "list.dashboard.batt-temp-in-cabinet",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__Cabinet__BatteryTempInCabinet' */'/app/src/pages/Dashboard/Cabinet/BatteryTempInCabinet'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/dashboard/cabinet-high-temp",
        "name": "list.dashboard.cabinet-high-temp",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__Cabinet__CabinetHighTemp' */'/app/src/pages/Dashboard/Cabinet/CabinetHighTemp'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/dashboard/veh-offline",
        "name": "list.dashboard.veh-offline",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__Vehicle__VehicleOffline' */'/app/src/pages/Dashboard/Vehicle/VehicleOffline'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/dashboard/veh-low-soc",
        "name": "list.dashboard.veh-low-soc",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__Vehicle__VehicleLowSoc' */'/app/src/pages/Dashboard/Vehicle/VehicleLowSoc'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/dashboard/motor-controller-abnormal",
        "name": "list.dashboard.motor-controller-abnormal",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__Vehicle__MotorControllerAbnormal' */'/app/src/pages/Dashboard/Vehicle/MotorControllerAbnormal'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "exact": true,
        "path": "/index.html",
        "redirect": "/dashboard"
      },
      {
        "exact": true,
        "path": "/",
        "redirect": "/dashboard"
      },
      {
        "name": "list.product-center",
        "path": "/product",
        "icon": "profile",
        "routes": [
          {
            "path": "/product",
            "redirect": "/product/vehicle/type",
            "exact": true
          },
          {
            "name": "vehicle",
            "path": "/product/vehicle",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Vehicle' */'/app/src/pages/Product/Vehicle'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/product/vehicle",
                "redirect": "/product/vehicle/list",
                "exact": true
              },
              {
                "path": "/product/vehicle/type",
                "name": "type",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Vehicle__TypeList' */'/app/src/pages/Product/Vehicle/TypeList'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/vehicle/inventory",
                "name": "inventory",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Vehicle__StockList' */'/app/src/pages/Product/Vehicle/StockList'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/vehicle/inventory/record",
                "name": "vehicle-stock-record",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Vehicle__StockRecord' */'/app/src/pages/Product/Vehicle/StockRecord'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/vehicle/inventory/record/detail",
                "name": "vehicle-stock-record-detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Vehicle__StockRecordDetail' */'/app/src/pages/Product/Vehicle/StockRecordDetail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/vehicle/list",
                "name": "list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Vehicle__List' */'/app/src/pages/Product/Vehicle/List'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/vehicle/list/vehicle-detail/:vehicleCode",
                "name": "vehicle-detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Vehicle__VehicleDetail' */'/app/src/pages/Product/Vehicle/VehicleDetail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/vehicle/list/trajectory/:vehicleCode",
                "name": "vehicle-trajectory",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Vehicle__Trajectory' */'/app/src/pages/Product/Vehicle/Trajectory'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/vehicle/distribution",
                "name": "distribution",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operation__Distribution__VehicleList' */'/app/src/pages/Operation/Distribution/VehicleList'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              }
            ]
          },
          {
            "name": "battery",
            "path": "/product/battery",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Battery' */'/app/src/pages/Product/Battery'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/product/battery",
                "redirect": "/product/battery/list",
                "exact": true
              },
              {
                "path": "/product/battery/type",
                "name": "type",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Battery__TypeList' */'/app/src/pages/Product/Battery/TypeList'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/battery/inventory",
                "name": "inventory",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Battery__StockList' */'/app/src/pages/Product/Battery/StockList'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/battery/inventory/record",
                "name": "battery-inventory-record",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Battery__StockRecord' */'/app/src/pages/Product/Battery/StockRecord'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/battery/inventory/record/detail",
                "name": "battery-inventory-record-detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Battery__StockRecordDetail' */'/app/src/pages/Product/Battery/StockRecordDetail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/battery/list",
                "name": "list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Battery__BatteryTabs' */'/app/src/pages/Product/Battery/BatteryTabs'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/battery/list/details/:batteryCode",
                "name": "battery-list-real",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Battery__RealTimeDetail' */'/app/src/pages/Product/Battery/RealTimeDetail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/battery/distribution",
                "name": "distribution",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operation__Distribution__BatteryList' */'/app/src/pages/Operation/Distribution/BatteryList'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              }
            ]
          },
          {
            "name": "cabinet",
            "path": "/product/cabinet",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Cabinet' */'/app/src/pages/Product/Cabinet'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/product/cabinet",
                "redirect": "/product/cabinet/list",
                "exact": true
              },
              {
                "path": "/product/cabinet/type",
                "name": "type",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Cabinet__TypeList' */'/app/src/pages/Product/Cabinet/TypeList'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/cabinet/inventory",
                "name": "inventory",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Cabinet__StockList' */'/app/src/pages/Product/Cabinet/StockList'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/cabinet/inventory/record",
                "name": "stock-record",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Cabinet__StockRecord' */'/app/src/pages/Product/Cabinet/StockRecord'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/cabinet/inventory/record/detail",
                "name": "stock-record-detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Cabinet__StockRecordDetail' */'/app/src/pages/Product/Cabinet/StockRecordDetail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/cabinet/list",
                "name": "list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Cabinet__CabinetTabs' */'/app/src/pages/Product/Cabinet/CabinetTabs'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/cabinet/list/gen3/:cabinetCode",
                "name": "cabinet-list-real",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Cabinet__RealTimeDetail' */'/app/src/pages/Product/Cabinet/RealTimeDetail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/cabinet/list/gen2/:cabinetCode",
                "name": "cabinet-list-real",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Cabinet__RealTimeDetailGen2' */'/app/src/pages/Product/Cabinet/RealTimeDetailGen2'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/cabinet/distribution",
                "name": "distribution",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operation__Distribution__CabinetList' */'/app/src/pages/Operation/Distribution/CabinetList'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "name": "list.operation-center",
        "path": "/operation",
        "icon": "tags",
        "routes": [
          {
            "path": "/operation",
            "redirect": "/operation/price/product-price",
            "exact": true
          },
          {
            "name": "price-management",
            "path": "/operation/price",
            "routes": [
              {
                "path": "/operation/price",
                "redirect": "/operation/price/product-price",
                "exact": true
              },
              {
                "path": "/operation/price/product-price",
                "name": "product-price",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operation__Price__ProductPrice' */'/app/src/pages/Operation/Price/ProductPrice'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation/price/vehicle-price",
                "name": "vehicle-price",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operation__Price__VehiclePrice' */'/app/src/pages/Operation/Price/VehiclePrice'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "name": "swap-station",
            "path": "/operation/swap-station",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operation__SwapStation__SwapStation' */'/app/src/pages/Operation/SwapStation/SwapStation'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "swap-station-detail",
            "path": "/operation/swap-station/detail/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operation__SwapStation__SwapStationDetail' */'/app/src/pages/Operation/SwapStation/SwapStationDetail'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "edit-swap-station",
            "path": "/operation/swap-station/edit/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operation__SwapStation__EditSwapStation' */'/app/src/pages/Operation/SwapStation/EditSwapStation'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "create-swap-station",
            "path": "/operation/swap-station/create",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operation__SwapStation__CreateSwapStation' */'/app/src/pages/Operation/SwapStation/CreateSwapStation'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "external-integration",
            "path": "/operation/external",
            "routes": [
              {
                "path": "/operation/external",
                "redirect": "/operation/external/fleet",
                "exact": true
              },
              {
                "path": "/operation/external/user-sso",
                "name": "user-sso",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operation__External__UserSSO' */'/app/src/pages/Operation/External/UserSSO'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation/external/user-sso/list/details",
                "name": "user-detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__Detail' */'/app/src/pages/User/Detail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/operation/external/fleet",
                "name": "fleet-management",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operation__Distribution__ExternalSystemList' */'/app/src/pages/Operation/Distribution/ExternalSystemList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation/external/promo",
                "name": "promo-engine",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operation__External__PromoEngine' */'/app/src/pages/Operation/External/PromoEngine'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "name": "notification-management",
            "path": "/operation/notification",
            "routes": [
              {
                "path": "/operation/notification",
                "redirect": "/operation/notification/list",
                "exact": true
              },
              {
                "path": "/operation/notification/list",
                "name": "notification",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operation__Notification__NotificationList' */'/app/src/pages/Operation/Notification/NotificationList'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "name": "history",
            "path": "/operation/history",
            "routes": [
              {
                "path": "/operation/history",
                "redirect": "/operation/history/swap/list",
                "exact": true
              },
              {
                "path": "/operation/history/swap/list",
                "name": "swap-history",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operation__History__SwapHistory' */'/app/src/pages/Operation/History/SwapHistory'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation/history/swap/detail/:recordNo",
                "name": "swap-history-detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operation__History__SwapHistoryDetail' */'/app/src/pages/Operation/History/SwapHistoryDetail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/operation/history/reservation/list",
                "name": "reserve-history",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operation__History__ReservationHistory' */'/app/src/pages/Operation/History/ReservationHistory'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation/history/reservation/detail/:recordNo",
                "name": "reserve-history-detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operation__History__ReservationHistoryDetail' */'/app/src/pages/Operation/History/ReservationHistoryDetail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/operation/history/alarm/records",
                "name": "alarm-record",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Alarm__AlarmRecord' */'/app/src/pages/Product/Alarm/AlarmRecord'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "name": "return",
            "path": "/operation/return",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operation__Return' */'/app/src/pages/Operation/Return'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "return-detail",
            "path": "/operation/return/details",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operation__Return__Detail' */'/app/src/pages/Operation/Return/Detail'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "name": "list.orders",
        "path": "/orders",
        "icon": "dollar",
        "routes": [
          {
            "name": "order-management",
            "routes": [
              {
                "name": "product-order",
                "path": "/orders/product",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Orders__ProductOrder' */'/app/src/pages/Orders/ProductOrder'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "product-order-detail",
                "path": "/orders/product/detail/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Orders__ProductOrderDetail' */'/app/src/pages/Orders/ProductOrderDetail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "name": "vehicle-order",
                "path": "/orders/vehicle",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Orders__VehicleOrder' */'/app/src/pages/Orders/VehicleOrder'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "vehicle-order-detail",
                "path": "/orders/vehicle/detail/:id/:type",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Orders__VehicleOrderDetail' */'/app/src/pages/Orders/VehicleOrderDetail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "name": "per-kwh-order",
                "path": "/orders/per-kwh",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Orders__PerKwh' */'/app/src/pages/Orders/PerKwh'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "name": "list.system",
        "path": "/system",
        "icon": "setting",
        "routes": [
          {
            "name": "system-management",
            "path": "/system/settings",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__SystemManagement__Settings' */'/app/src/pages/System/SystemManagement/Settings'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "upgrade-management",
            "path": "/system/upgrade",
            "routes": [
              {
                "path": "/system/upgrade",
                "redirect": "/system/upgrade/System/UpgradeManagement/UpdateRecord",
                "exact": true
              },
              {
                "path": "/system/upgrade/update-record",
                "name": "update-record",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__UpgradeManagement__UpdateRecord' */'/app/src/pages/System/UpgradeManagement/UpdateRecord'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/upgrade/version-management",
                "name": "version-management",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__UpgradeManagement__VersionManagement' */'/app/src/pages/System/UpgradeManagement/VersionManagement'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "name": "audit-log",
            "path": "/system/audit-log",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__AuditLog' */'/app/src/pages/System/AuditLog'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/app/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}

export default {
  "vehicle-inventory.vcu-number": "VCU Number",
  "vehicle-inventory.vehicle-name": "Vehicle Name",
  "vehicle-inventory.vehicle-type": "Vehicle Type",
  "vehicle-inventory.vehicle-brand": "Vehicle Brand",
  "vehicle-inventory.compartment-type": "Vehicle Compartment Type",
  "vehicle-inventory.online-status": "Online Status",
  "vehicle-inventory.stock-status": "Inventory Status",
  "vehicle-inventory.motor-number": "Motor Number",
  "vehicle-inventory.engine-number": "Engine Number",
  "vehicle-inventory.vin-number": "VIN Number",
  "vehicle-inventory.offline": "Offline",
  "vehicle-inventory.online": "Online",
  "vehicle-inventory.out-stock": "Out of Stock",
  "vehicle-inventory.in-stock": "In Stock",
  "vehicle-inventory.edit": "Edit",
  "vehicle-inventory.delete": "Delete",
  "vehicle-inventory.confirm": "Confirm",
  "vehicle-inventory.double-battery": "Double Battery",
  "vehicle-inventory.single-battery": "Single Battery",
  "vehicle-inventory.common.copy-success": "Copied",
  "vehicle-inventory.common.action": "Action",
  "vehicle-inventory.common.cancel": "Cancel",
  "vehicle-inventory.common.confirm": "Confirm",
  "vehicle-inventory.common.remind": "Remind",
  "vehicle-inventory.common.delete-desc":
    "Are you sure you want to delete this inventory?",
  "vehicle-inventory.common.delete-success": "Delete Success",
  "vehicle-inventory.stock.inventory-record": "Inventory Record",
  "vehicle-inventory.stock.export": "Export to Distribution",
  "vehicle-inventory.stock.import": "Import",
  "vehicle-inventory.stock.new": "Add New",
  "vehicle-inventory.export-csv": "Export CSV Data",
  "vehicle-inventory.vehicle-name.required": "Vehicle Name is required",
  "vehicle-inventory.vcu-number.required": "VCU Number is required",
  "vehicle-inventory.vehicle-type.required": "Vehicle Type is required",
  "vehicle-inventory.vcu-number.invalid":
    "letters, numbers, underline and minus are supported, the length limit is 1～20",
  "vehicle-inventory.register-date": "Date of production",
  "vehicle-inventory.add-success": "Add New Inventory Success",
  "vehicle-inventory.detail.title": "Vehicle Detail",
  "vehicle-inventory.model-name": "Model",
  "vehicle-inventory.brand-name": "Brand Name",
  "vehicle-inventory.save": "Save",
  "vehicle-inventory.edit.title": "Edit Vehicle Inventory",
  "vehicle-inventory.edit-success": "Edit Inventory Success",
  "vehicle-inventory.record.batch": "Batch",
  "vehicle-inventory.record.date": "Date",
  "vehicle-inventory.record.operation": "Operation",
  "vehicle-inventory.record.vehicle-type": "Vehicle Type",
  "vehicle-inventory.record.number": "Number",
  "vehicle-inventory.record.operator": "Operator",
  "vehicle-inventory.record.stock-in-out-record": "Stock In/Out Record",
  "vehicle-inventory.common.details": "Details",
  "vehicle-inventory.vehicle-code": "Vehicle Code",
  "vehicle-inventory.stock.success-export": "Export Success",
  "vehicle-inventory.stock.export-desc":
    "Are you sure you want to export {quantity} vehicles?",
  "vehicle-inventory.common.tip": "Tip",
  "vehicle-inventory.error.please-check": "Please check the selected vehicles",
  "vehicle-inventory.create": "New Vehicle Inventory"
};

export default {
  // Battery Alarm
  "alarms.battery.battery-number": "Battery Number",
  "alarms.battery.battery-name": "Battery Name",
  "alarms.battery.imei": "IMEI",
  "alarms.battery.battery-type-name": "Battery Type Name",
  "alarms.battery.battery-type": "Battery Type",
  "alarms.battery.battery-brand": "Battery Brand",
  "alarms.battery.cell-brand": "Cell Brand",
  "alarms.battery.number-cell": "Number of Cells",
  "alarms.battery.bms-brand": "BMS Brand",
  "alarms.battery.structure": "Battery Structure",
  "alarms.battery.soc": "SOC",
  "alarms.battery.char-switch": "Charging Switch",
  "alarms.battery.dischar-switch": "Discharge Switch",
  "alarms.battery.cycle-time": "Cycle Times",
  "alarms.battery.curr-curr": "Current Current",
  "alarms.battery.curr-vol": "Current Voltage",
  "alarms.battery.batt-info": "Battery Information",
  "alarms.battery.tlb": "Ternary lithium battery",
  "alarms.battery.lfp": "Lithium iron phosphate battery",
  "alarms.battery.allow-discharge": "Allowable Discharge",
  "alarms.battery.no-discharge": "No Discharge",
  "alarms.battery.permitted-charge": "Permitted Charging",
  "alarms.battery.no-charge": "No Charging",

  // Cabinet Alarm
  "alarms.cabinet.cabinet-number": "Cabinet Number",
  "alarms.cabinet.cabinet-name": "Cabinet Name",
  "alarms.cabinet.serial-no": "Android Serial Number",
  "alarms.cabinet.cabinet-type-name": "Cabinet Type Name",
  "alarms.cabinet.cabinet-type": "Cabinet Type",
  "alarms.cabinet.cabinet-brand": "Cabinet Brand",
  "alarms.cabinet.cabin-no": "Cabinet No.",
  "alarms.cabinet.power-supply": "Power Supply State",
  "alarms.cabinet.curr-humidity": "Current Humidity",
  "alarms.cabinet.network-type": "Network Type",
  "alarms.cabinet.signal-intensity": "Signal Intensity",
  "alarms.cabinet.power-meter": "Power Meter",
  "alarms.cabinet.cabinet-temp": "Cabinet Temperature",
  "alarms.cabinet.cabinet-lamp": "Main Cabinet Lamp",
  "alarms.cabinet.curr-total-power-charger": "Current Total Power of Charger (W)",
  "alarms.cabinet.total-power-char": "Total Power of Battery Charging (W)",
  "alarms.cabinet.open": "Open",
  "alarms.cabinet.close": "Close",
  "alarms.cabinet.low": "Low",
  "alarms.cabinet.high": "High",
  "alarms.cabinet.medium": "Medium",
  "alarms.cabinet.cabinet-info": "Cabinet Information",

  // Alarm Records
  "alarms.record.device-number": "Device Number",
  "alarms.record.device-name": "Device Name",
  "alarms.record.imei-serial": "IMEI/Serial Number",
  "alarms.record.device-type": "Device Type",
  "alarms.record.alarm-time": "Alarm Time",
  "alarms.record.battery": "Battery",
  "alarms.record.cabinet": "Cabinet",

  // Common
  "alarms.common.alarm-content": "Alarm Content",
  "alarms.common.level": "Level",
  "alarms.common.rated-curr": "Rated Current(A)",
  "alarms.common.rated-vol": "Rated Voltage(V)",
  "alarms.common.rated-cap": "Rated Capacity(AH)",
  "alarms.common.soft-ver": "Software Version",
  "alarms.common.hard-ver": "Hardware Version",
  "alarms.common.comm-type": "Communication Type",
  "alarms.common.status": "Status",
  "alarms.common.more-real-info": "More Real-Time Information",
  "alarms.common.real-info": "Real-Time Information",
  "alarms.common.loc-add": "Location Address",
  "alarms.common.ethernet": "Ethernet",
  "alarms.common.all": "All",
  "alarms.common.online": "Online",
  "alarms.common.offline": "Offline",
  "alarms.common.click-check": "Click to Check",
  "alarms.common.processing": "Processing",
  "alarms.common.record": "Record",
  "alarms.common.time": "Time",
  "alarms.common.cabin": "Door No.",
  "alarms.common.normal": "NORMAL",
};

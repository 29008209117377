export default {
  "role-list.role-name": "Role Name",
  "role-list.merchant": "Merchant",
  "role-list.role-description": "Role Description",
  "role-list.sort": "Sort",
  "role-list.creation-time": "Creation Time",
  "role-list.add-new": "Add New",
  "role-list.create-success": "Create New Role Success",
  "role-list.edit-role": "Edit Role"
};

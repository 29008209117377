export default {
  "operation.price.product.productId": "Product ID",
  "operation.price.product.productName": "Name of Product",
  "operation.price.product.vehicleType": "Vehicle Type",
  "operation.price.product.operationType": "Operation Type",
  "operation.price.product.noOfCycle": "No. of Cycle",
  "operation.price.product.noOfBattery": "No. of Battery",
  "operation.price.product.renewAmount": "Renew Amount",
  "operation.price.product.city": "City",
  "operation.price.product.packageContent": "Package Content",
  "operation.price.product.enablePromoCronJob": "Enable Promo Cron Job",
  "operation.price.product.usageState": "Usage State",
  "operation.price.product.display": "Display",
  "operation.price.product.unlimitedNumber": "Unlimited Number",
  "operation.common.number": "{number} swap",
  "operation.price.product.noTimeLimit": "No Time Limit",
  "operation.price.product.day": "Day",
  "operation.price.product.month": "Month",
  "operation.price.product.year": "Year",
  "operation.price.product.new": "New Product",
  "operation.price.product.description": "Description",
  "operation.price.product.productName.required": "Product name is required",
  "operation.price.product.operationType.sale": "Sale",
  "operation.price.product.operationType.lease": "Lease",
  "operation.price.product.operationType.leaseToOwn": "Lease to Own",
  "operation.price.product.operationType.payPerKwh": "Pay per Kwh",
  "operation.price.product.noOfCycle.required": "No. of cycle is required",
  "operation.price.product.unlimited": "Unlimited",
  "operation.price.product.deposit": "Deposit",
  "operation.price.product.deposit.required": "Deposit is required",
  "operation.price.product.adminFee": "Admin Fee",
  "operation.price.product.adminFee.required": "Admin fee is required",
  "operation.price.product.renewalPrice": "Renewal Price",
  "operation.price.product.renewalPrice.required": "Renewal price is required",
  "operation.price.product.province": "Province",
  "operation.price.product.noOfSwapPerCycle": "Amount of swaps",
  "operation.price.product.noOfSwapPerCycle.required":
    "Amount of swaps is required",
  "operation.price.product.timeLimit": "Cycle Interval",
  "operation.price.product.timeLimit.required": "Cycle interval is required",
  "operation.price.product.hasAgreement": "Has Agreement",
  "operation.price.product.vehicle": "Vehicle",
  "operation.price.product.battery": "Battery",
  "operation.price.product.swapProduct": "Battery Swap Product",
  "operation.price.product.customerStatus": "Customer Status",
  "operation.price.product.add-success": "Add new product successfully",
  "operation.price.product.vehicleType.required": "Vehicle type is required",
  "operation.price.product.edit-success": "Edit product successfully"
};

// @ts-nocheck

  import DashboardOutlined from '@ant-design/icons/es/icons/DashboardOutlined';
import ProfileOutlined from '@ant-design/icons/es/icons/ProfileOutlined';
import TagsOutlined from '@ant-design/icons/es/icons/TagsOutlined';
import DollarOutlined from '@ant-design/icons/es/icons/DollarOutlined';
import SettingOutlined from '@ant-design/icons/es/icons/SettingOutlined'
  export default {
    DashboardOutlined,
ProfileOutlined,
TagsOutlined,
DollarOutlined,
SettingOutlined
  }
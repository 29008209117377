export default {
  // Vehicle List
  "distribution.vehicle.vcu-no": "VCU No.",
  "distribution.vehicle.veh-name": "Vehicle Name",
  "distribution.vehicle.veh-com-type": "Vehicle Compartment Type",
  "distribution.vehicle.single-batt": "Single Battery",
  "distribution.vehicle.double-batt": "Double Battery",
  "distribution.vehicle.veh-alloc-details": "Vehicle Allocation Details",
  "distribution.vehicle.veh-brand": "Vehicle Brand",
  "distribution.vehicle.veh-type": "Vehicle Type",
  "distribution.vehicle.veh-edit-title": "Vehicle Distribution - Edit",

  // Battery List
  "distribution.battery.batt-number": "Battery Number",
  "distribution.battery.batt-name": "Battery Name",
  "distribution.battery.imei": "IMEI",
  "distribution.battery.batt-alloc-details": "Battery Distribution Details",
  "distribution.battery.batt-brand": "Battery Brand",
  "distribution.battery.batt-type": "Battery Type",
  "distribution.battery.batt-edit-title": "Battery Distribution - Edit",

  // Cabinet List
  "distribution.cabinet.cabinet-number": "Cabinet Number",
  "distribution.cabinet.cabinet-name": "Cabinet Name",
  "distribution.cabinet.serial-no": "Android Serial No.",
  "distribution.cabinet.cabin-amount": "Amount of Cabin",
  "distribution.cabinet.comm-type": "Communication Type",
  "distribution.cabinet.cabin-desc": "{count}grid",
  "distribution.cabinet.cabinet-brand": "Cabinet Brand",
  "distribution.cabinet.cabinet-type": "Cabinet Type",
  "distribution.cabinet.cabinet-detail-title": "Details of Electric Cabinets",
  "distribution.cabinet.cabinet-edit-title": "Cabinet Distribution - Edit",
  "distribution.cabinet.integra-station": "Integrated station",
  "distribution.cabinet.swap-station": "Battery swapping station",
  "distribution.cabinet.site-type": "Site Type",
  "distribution.cabinet.site-name": "Site Name",
  "distribution.cabinet.number": "Number",

  // External List
  "distribution.external.battery-list": "Bind Battery List",
  "distribution.external.add-batt": "Add Battery",
  "distribution.external.update-batt": "Update Battery",
  "distribution.external.exceed-compartment": "Number of Battery cannot exceed vehicle compartment setup",
  "distribution.external.add-success": "Add Battery Successful",

  // Common
  "distribution.common.type-name": "Type Name",
  "distribution.common.online-status": "Status",
  "distribution.common.trans-type": "Transaction Type",
  "distribution.common.price": "Price",
  "distribution.common.deposit": "Deposit",
  "distribution.common.rent": "Rent",
  "distribution.common.time": "Time",
  "distribution.common.dis-state": "Distribution State",
  "distribution.common.scrap-status": "Scrap Status",
  "distribution.common.unallocated": "Unallocated",
  "distribution.common.allocated": "Allocated",
  "distribution.common.online": "Online",
  "distribution.common.offline": "Offline",
  "distribution.common.scrapped": "SCRAPPED",
  "distribution.common.normal": "NORMAL",
  "distribution.common.testing": "TESTING",
  "distribution.common.for-sale": "For Sale",
  "distribution.common.for-rent": "For Rent",
  "distribution.common.for-test": "For Test",
  "distribution.common.not-sale-rent": "Not for sale or rent",
  "distribution.common.set-time": "Set Time",
  "distribution.common.edit": "Edit",
  "distribution.common.using": "Using",
  "distribution.common.scrap": "Scrap",
  "distribution.common.test": "Test",
  "distribution.common.market-mode": "Marketing Mode",
  "distribution.common.scrap-desc": "Confirm that the device:{deviceCode} is set to {status} state",
  "distribution.common.allocation": "Allocation",
  "distribution.common.corp-name": "Corporate Name",
  "distribution.common.dis-type": "Distribution Type",
  "distribution.common.pic": "Person in Charge",
  "distribution.common.cell-phone": "Cell-phone Number",
  "distribution.common.agent": "Agent",
  "distribution.common.run-direct": "Run directly by Oyika",
  "distribution.common.success-dis": "Successful Distribution",
  "distribution.common.ethernet": "Ethernet",
  "distribution.common.all": "All",

  // Error
  "distribution.common.error.sale-empty": "Sales price cannot be empty",
  "distribution.common.error.sale-two-decimal": "The selling price must be a number with a maximum of two decimal places",
  "distribution.common.error.sale-less-0": "Sales price cannot be less than 0",
  "distribution.common.error.deposit-empty": "Deposit cannot be empty",
  "distribution.common.error.deposit-two-decimal": "The deposit must be a number with a maximum of two decimal places",
  "distribution.common.error.deposit-less-0": "Deposit cannot be less than 0",
  "distribution.common.error.rent-empty": "Rent cannot be empty",
  "distribution.common.error.rent-two-decimal": "Rent must be a number with a maximum of two decimal places",
  "distribution.common.error.rent-less-0": "Rent cannot be less than 0",
  "distribution.common.error.param-empty": "Parameter cannot be empty",
  "distribution.common.error.time-greater-0": "Time must be greater than 0",
  "distribution.common.error.time-must-int": "Time must be an integer",
  "distribution.common.error.please-select": "Please check the products in the form before distribution!",
  "distribution.common.error.tick-battery": "Please tick the battery before add the battery!",
};

export default {
  "admin-list.add-new": "Add New",
  "admin-list.account-number": "Account Number",
  "admin-list.merchant": "Merchant",
  "admin-list.identity-type": "Identity Type",
  "admin-list.account-type": "Account Type",
  "admin-list.account-status": "Account Status",
  "admin-list.last-login-time": "Last Login Time",
  "admin-list.create-time": "Creation Time",
  "admin-list.identity-type.scin": "Oyika administrator",
  "admin-list.identity-type.self-support": "Self-Support",
  "admin-list.identity-type.agent": "Agent",
  "admin-list.identity-type.integrated-station": "Integrated Station",
  "admin-list.identity-type.battery-swap-station": "Battery Swap Station",
  "admin-list.account-type.general-admin": "General administrator",
  "admin-list.account-type.sub-admin": "Sub account",
  "admin-list.account-type.super-admin": "Super Administrator",
  "admin-list.account-status.normal": "Normal",
  "admin-list.account-status.prohibit": "Prohibit",
  "admin-list.new-account": "New Account",
  "admin-list.password": "Password",
  "admin-list.confirm-password": "Confirm Password",
  "admin-list.permission": "Permission",
  "admin-list.account-assignment": "Account Assignment",
  "admin-list.edit-account": "Edit Account",
  // password not match
  "admin-list.password-not-match": "Password does not match",
  "admin-list.password.pattern":
    "Password must be at least 8 characters long and contain at least one uppercase letter and one special character",
  "admin-list.account-number.no-spaces": "Account number cannot contain spaces"
};

export default {
  "return.assets.submit-by-subordinate": "Submitted By The Subordinate",
  "return.assets.submit-to-superior": "Submitted To A Superior",
  "return.assets.number": "Number",
  "return.assets.type": "Type",
  "return.assets.application-status": "Application Status",
  "return.assets.number-devices": "Number of Devices",
  "return.assets.time-application": "Time of Application",
  "return.assets.merchant": "Merchant",
  "return.assets.view-details": "View Details",
  "return.assets.vehicle": "Vehicle",
  "return.assets.battery": "Battery",
  "return.assets.cabinet": "Cabinet",
  "return.assets.audit": "Request For Approval",
  "return.assets.handle": "Inspection In Progress",
  "return.assets.complete": "Inspection Completed",
  "return.assets.reject": "Request Rejected",
  "return.assets.cancel": "Request Cancel",
  "return.assets.device-name": "Device Name",
  "return.assets.device-num": "Device Number",
  "return.assets.model": "Model",
  "return.assets.appli-info": "Application Information",
  "return.assets.state": "State",
  "return.assets.auditor": "Auditor",
  "return.assets.audit-time": "Audit Time",
  "return.assets.handler": "Handler",
  "return.assets.handle-time": "Handling Time",
  "return.assets.initiator": "Initiator",
  "return.assets.desc": "Description",
  "return.assets.back": "Back",
  "return.assets.replace": "Replace",
  "return.assets.audit-desc": "Do you confirm acceptance of this work order?",
  "return.assets.reject-desc": "Do you confirm the rejection of the work order?",
  "return.assets.complete-desc": "Have you confirmed the completion of the work order?",
  "return.assets.cancel-desc": "Are you sure to cancel the work order?",
  "return.assets.manual-enter": "Manually Enter",
  "return.assets.upload-file": "Upload a File",
  "return.assets.device-type": "Device Type",
  "return.assets.input": "Input",
  "return.assets.file": "File",
  "return.assets.download-temp": "Download The Template",
  "return.assets.no-file": 'No File Uploaded',
  "return.assets.ranking": "Ranking",
  "return.assets.failed": "Failed",
};

export default {
  // Title
  "dashboard.battery": "Battery",
  "dashboard.cabinet": "Cabinet",
  "dashboard.vehicle": "Vehicle",
  "dashboard.swap": "Swap",
  "dashboard.alloc-idle": "Battery Allocation But Idle",
  "dashboard.wrong-loc": "Battery Wrong Location",
  "dashboard.batt-offline": "Battery Offline",
  "dashboard.batt-starvation": "Battery Starvation",
  "dashboard.char-outside": "Charging Outside Cabinet",
  "dashboard.wrong-loc.tip": "Batteries that are in the wrong location. (too far away from merchant)",
  "dashboard.char-outside.tip": "Batteries that are charging outside the cabinet",
  "dashboard.batt-starvation.tip": "Batteries with little to no energy left",

  // Battery Alarm
  "dashboard.battery.char-over-curr": "Charging Over Current",
  "dashboard.battery.cell-over-volt": "Cell Over Voltage",
  "dashboard.battery.short-circuit": "Short Circuit",
  "dashboard.battery.cell-low-volt": "Cell Low Voltage",
  "dashboard.battery.cell-open-circuit": "Cell Open Circuit",
  "dashboard.battery.low-cap": "Low Capacity",
  "dashboard.battery.dischar-over-curr": "Discharging Over Current",
  "dashboard.battery.temp-sensor-open": "Temp Sensor Open Circuit",
  "dashboard.battery.cell-high-temp": "Cell High Temperature",
  "dashboard.battery.cell-low-temp": "Cell Low Temperature",
  "dashboard.battery.bms-high-temp": "BMS High Temperature",
  "dashboard.battery.high-humidity": "High Humidity",
  "dashboard.battery.batt-site-dischar": "Battery In Site Discharges",

  // Cabinet Alarm
  "dashboard.cabinet.batt-bound-other-user": "Battery Bound By Another User",
  "dashboard.cabinet.batt-inspec-failed": "Battery Inspection Failed",
  "dashboard.cabinet.batt-not-alloc": "Battery Not Allocated",
  "dashboard.cabinet.batt-returning": "Battery Return In Progress",
  "dashboard.cabinet.batt-removed-abnormal": "Battery Was Removed Abnormally",
  "dashboard.cabinet.batt-replaced-abnormal": "Battery Was Replaced Abnormally",
  "dashboard.cabinet.char-abnormal": "Charging Abnormal",
  "dashboard.cabinet.door-in-open": "Door In Open Status",
  "dashboard.cabinet.door-failed": "Door Status Failed",
  "dashboard.cabinet.door-no-res": "Door Status No Respond",
  "dashboard.cabinet.failed-initial-slow": "Failed To Initialize Slot",
  "dashboard.cabinet.failed-open-door": "Failed To Open Door",
  "dashboard.cabinet.failed-set-bms": "Failed to set BMS Status",
  "dashboard.cabinet.limit-switch-abnormal": "Limit Switch Detect Abnormal",
  "dashboard.cabinet.limit-switch-no": "Limit Switch No,BCB Yes",
  "dashboard.cabinet.limit-switch-yes": "Limit Switch Yes,BCB No",
  "dashboard.cabinet.no-batt-available": "No Battery Available",

  "dashboard.cabinet.batt-temp-cabinet": "Battery Temp In Cabinet",
  "dashboard.cabinet.cabin-disabled": "Cabinet Door Disabled",
  "dashboard.cabinet.cabin-not-charging": "Cabinet Door Not Charging",
  "dashboard.cabinet.cabinet-high-temp": "Cabinet High Temp",
  "dashboard.cabinet.cabinet-offline": "Cabinet Offline",
  "dashboard.cabinet.cabinet-smoke": "Cabinet Smoke",
  "dashboard.cabinet.cabinet-water": "Cabinet Water",

  // Vehicle Alarm
  "dashboard.vehicle.veh-offline": "Vehicle Offline",
  "dashboard.vehicle.veh-low-soc": "Vehicle Low SOC",
  "dashboard.vehicle.motor-control-abnormal": "Motor Controller Abnormal",

  // Swap
  "dashboard.swap.swap-counts": "Swap Counts",
  "dashboard.swap.complete-swap": "Complete Swap",
  "dashboard.swap.fail-swap": "Fail Swap",
  "dashboard.swap.total-complete-swap": "Total Complete Swap",
  "dashboard.swap.total-fail-swap": "Total Failed Swap",
  "dashboard.swap.failure-rate": "Failure Rate",
  "dashboard.swap.top-failed-swap": "Top Failed Swap",
  "dashboard.swap.rank": "Rank",
  "dashboard.swap.cabinet-name": "Cabinet Name",
  "dashboard.swap.site-loc": "Site Location",
  "dashboard.swap.percentage": "Percentage (%)",
  "dashboard.swap.swap-map": "Swap Map",
  "dashboard.swap.top-5-cabinet": "Top 5 Cabinet",
  "dashboard.swap.lowest-5-cabinet": "Lowest 5 Cabinet",

  // Common
  "dashboard.common.batt-code": "Battery Number",
  "dashboard.common.merchant": "Merchant",
  "dashboard.common.merchant-placeholder": "Select Merchant",
  "dashboard.common.location": "Location",
  "dashboard.common.last-online": "Last Online",
  "dashboard.common.days-last-onilne": "Days Since Last Online",
  "dashboard.common.asset-util-status": "Deployment Status",
  "dashboard.common.online-status": "Status",
  "dashboard.common.lastest-time": "Lastest Time",
  "dashboard.common.online": "Online",
  "dashboard.common.offline": "Offline",
  "dashboard.common.soc": "SOC",
  "dashboard.common.note": "Note",
  "dashboard.common.proximity": "Proximity(Km)",
  "dashboard.common.last-swap": "Last Swap",
  "dashboard.common.last-7": "Last 7 days",
  "dashboard.common.last-14": "Last 14 days",
  "dashboard.common.alarms-trend": "Alarms Trend",
  "dashboard.common.alarms-batt-list": "Battery Alarm List",
  "dashboard.common.alarms-cabinet-list": "Cabinet Alarm List",
  "dashboard.common.alarms-veh-list": "Vehicle Alarm List",
  "dashboard.common.real-time": "Real Time",
  "dashboard.common.view-all": "View All",
  "dashboard.common.view": "View",
  "dashboard.common.shipping": "Shipping",
  "dashboard.common.scrapped": "Scrapped",
  "dashboard.common.maintenance": "Maintenance",
  "dashboard.common.warehouse": "Warehouse",
  "dashboard.common.ready-use": "Ready-to-use",
  "dashboard.common.deployed": "Deployed",
  "dashboard.common.idle": "Idle",
  "dashboard.common.alarm-count": "Alarm Count",
  "dashboard.common.alarm-type": "Alarm Type",
  "dashboard.common.alarm-level": "Alarm Level",
  "dashboard.common.voltage": "Voltage",
  "dashboard.common.curr-elec": "Current Electricity",
  "dashboard.common.refresh": "Refresh",
  "dashboard.common.cabinet-number": "Cabinet Number",
  "dashboard.common.high": "High",
  "dashboard.common.medium": "Medium",
  "dashboard.common.low": "Low",
  "dashboard.common.cabinet-status": "Cabinet Status",
  "dashboard.common.alarm-date": "Alarm Date",
  "dashboard.common.site-name": "Site Name",
  "dashboard.common.door-number": "Door No.",
  "dashboard.common.door-status": "Door Status",
  "dashboard.common.batt-temp": "Battery Temperature (°C)",
  "dashboard.common.temp": "Temp (°C)",
  "dashboard.common.connected": "Connected",
  "dashboard.common.unconnected": "Unconnected",
  "dashboard.common.in-place": "In place",
  "dashboard.common.not-in-place": "Not in place",
  "dashboard.common.nfc-status": "NFC Status",
  "dashboard.common.in-place-detection": "In-Place Detection",
  "dashboard.common.fault-reason": "Fault Reason",
  "dashboard.common.batt-soc": "Battery SOC(%)",
  "dashboard.common.normal": "Normal",
  "dashboard.common.fault": "Fault",
  "dashboard.common.disabled": "Disabled",
  "dashboard.common.start-date": "Start Date",
  "dashboard.common.end-date": "End Date",
  "dashboard.common.screen-shot-graph": "Screen Shot Graph",
  "dashboard.common.screen-shot-trend": "Screen Shot Trend",
  "dashboard.common.get-screen-shot": "Get Screen Shot",
  "dashboard.common.export-data": "Export Data",
  "dashboard.common.veh-name": "Vehicle Name",
  "dashboard.common.vcu-num": "VCU Number",
  "dashboard.common.mobile-num": "Mobile Number",
  "dashboard.common.motor-control-status": "Motor Controller Status",
  "dashboard.common.total-soc": "Total SOC(%)",
  "dashboard.common.batt-count": "Battery Count",
};

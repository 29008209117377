// @ts-nocheck

import DashboardOutlined from '@ant-design/icons/DashboardOutlined';
import ProfileOutlined from '@ant-design/icons/ProfileOutlined';
import TagsOutlined from '@ant-design/icons/TagsOutlined';
import DollarOutlined from '@ant-design/icons/DollarOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined'

export default {
  DashboardOutlined,
ProfileOutlined,
TagsOutlined,
DollarOutlined,
SettingOutlined
}
    
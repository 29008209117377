export default {
  "pages.layouts.userLayout.title": "Admin Payment Platform",
  "pages.login.accountLogin.tab": "Account Login",
  "pages.login.accountLogin.errorMessage": "Incorrect username/password",
  "pages.login.failure": "Login failed, please try again!",
  "pages.login.success": "Login successful!",
  "pages.login.username.placeholder": "mobile number/username",
  "pages.login.username.required": "Please input your username!",
  "pages.login.password.placeholder": "Password",
  "pages.login.password.required": "Please input your password!",
  "pages.login.phoneLogin.tab": "Phone Login",
  "pages.login.phoneLogin.errorMessage": "Verification Code Error",
  "pages.login.phoneNumber.placeholder": "Phone Number",
  "pages.login.phoneNumber.required": "Please input your phone number!",
  "pages.login.phoneNumber.invalid": "Phone number is invalid!",
  "pages.login.captcha.placeholder": "Verification Code",
  "pages.login.captcha.required": "Please input verification code!",
  "pages.login.phoneLogin.getVerificationCode": "Get Code",
  "pages.getCaptchaSecondText": "sec(s)",
  "pages.login.rememberMe": "Remember me",
  "pages.login.forgotPassword": "Forgot Password ?",
  "pages.login.submit": "Login",
  "pages.login.loginWith": "Login with :",
  "pages.login.registerAccount": "Register Account",
  "pages.welcome.advancedComponent": "Advanced Component",
  "pages.welcome.link": "Welcome",
  "pages.welcome.advancedLayout": "Advanced Layout",
  "pages.welcome.alertMessage": "Put anything here",
  "pages.admin.subPage.title": "This page can only be viewed by Admin",
  "pages.admin.subPage.alertMessage":
    "Umi ui is now released, welcome to use npm run ui to start the experience.",
  "pages.searchTable.createForm.newRule": "New Rule",
  "pages.searchTable.updateForm.ruleConfig": "Rule configuration",
  "pages.searchTable.updateForm.basicConfig": "Basic Information",
  "pages.searchTable.updateForm.ruleName.nameLabel": "Rule Name",
  "pages.searchTable.updateForm.ruleName.nameRules":
    "Please enter the rule name!",
  "pages.searchTable.updateForm.ruleDesc.descLabel": "Rule Description",
  "pages.searchTable.updateForm.ruleDesc.descPlaceholder":
    "Please enter at least five characters",
  "pages.searchTable.updateForm.ruleDesc.descRules":
    "Please enter a rule description of at least five characters!",
  "pages.searchTable.updateForm.ruleProps.title": "Configure Properties",
  "pages.searchTable.updateForm.object": "Monitoring Object",
  "pages.searchTable.updateForm.ruleProps.templateLabel": "Rule Template",
  "pages.searchTable.updateForm.ruleProps.typeLabel": "Rule Type",
  "pages.searchTable.updateForm.schedulingPeriod.title":
    "Set Scheduling Period",
  "pages.searchTable.updateForm.schedulingPeriod.timeLabel": "Starting Time",
  "pages.searchTable.updateForm.schedulingPeriod.timeRules":
    "Please choose a start time!",
  "pages.searchTable.titleDesc": "Description",
  "pages.searchTable.ruleName": "Rule name is required",
  "pages.searchTable.titleCallNo": "Number of Service Calls",
  "pages.searchTable.titleStatus": "Status",
  "pages.searchTable.nameStatus.default": "default",
  "pages.searchTable.nameStatus.running": "running",
  "pages.searchTable.nameStatus.online": "online",
  "pages.searchTable.nameStatus.abnormal": "abnormal",
  "pages.searchTable.titleUpdatedAt": "Last Scheduled at",
  "pages.searchTable.exception": "Please enter the reason for the exception!",
  "pages.searchTable.titleOption": "Option",
  "pages.searchTable.config": "Configuration",
  "pages.searchTable.subscribeAlert": "Subscribe to alerts",
  "pages.searchTable.title": "Enquiry Form",
  "pages.searchTable.new": "New",
  "pages.searchTable.chosen": "chosen",
  "pages.searchTable.item": "item",
  "pages.searchTable.totalServiceCalls": "Total Number of Service Calls",
  "pages.searchTable.tenThousand": "0000",
  "pages.searchTable.batchDeletion": "bacth deletion",
  "pages.searchTable.batchApproval": "batch approval",

  //newly added to all page
  "pages.searchTable.delete": "Delete",
  "pages.searchTable.deactivate": "Deactivate",
  "pages.searchTable.edit": "Edit",
  "pages.searchTable.activate": "Activate",
  "pages.searchTable.activeteText": "Are you sure to activate ?",
  "pages.searchTable.deactiveteText": "Are you sure to deactivate ?",
  "pages.searchTable.status": "Status",

  "pages.ok": "OK",
  "pages.cancel": "Cancel",
  "pages.save": "Save",
  "pages.yes": "Yes",
  "pages.confirm": "Confirm",
  "pages.clear": "Clear",
  "pages.add-new": "Add New",

  "pages.activating": "Activating...",
  "pages.deactivating": "Deactivating...",
  "pages.activate": "Are you sure to activate?",
  "pages.deactivate": "Are you sure to deactivate?",

  //roles page
  "pages.roles.searchTable.title": "Roles List",
  "pages.roles.searchTable.updateForm.name.nameLabel": "Name",
  "pages.roles.searchTable.updateForm.name.description": "Name",
  "pages.roles.searchTable.username.generalSearch": "General Search",
  "pages.roles.searchTable.users": "Users",
  "pages.roles.searchTable.titleStatus": "Status",
  "pages.roles.searchTable.lastUpdate": "Last Update",
  "pages.roles.searchTable.active": "ACTIVE",
  "pages.roles.searchTable.inactive": "INACTIVE",
  "pages.roles.searchTable.actions": "Actions",

  "pages.roles.searchTable.updateForm.ruleConfig": "Update Roles",
  "pages.roles.searchTable.updateForm.ruleName.nameLabel": "Roles Name",
  "pages.roles.searchTable.updateForm.ruleName.nameRules":
    "Please Enter Roles Name",
  "pages.roles.searchTable.updateForm.status": "Status",
  "pages.roles.searchTable.updateForm.ruleStatus": "Please Select Status",
  "pages.roles.searchTable.updateForm.modifiedDate": "Last Update",
  "pages.roles.searchTable.updateForm.permission": "Permission",
  "pages.roles.searchTable.updateForm.id": "Id",

  "pages.roles.searchTable.createForm.title": "Add Role",
  "pages.roles.searchTable.createForm.name": "Name",
  "pages.roles.searchTable.createForm.ruleName:": "Please Enter Roles Name",

  "pages.roles.activateForm.id": "ID",
  "pages.roles.deleteForm.id": "ID",

  //users page
  "pages.users.title": "User List",
  "pages.users.searchTable.username.nameLabel": "Username",
  "pages.users.searchTable.username.description": "Username",
  "pages.users.searchTable.username.generalSearch": "General Search",
  "pages.users.searchTable.age": "Age",
  "pages.users.searchTable.name": "Name",
  "pages.users.searchTable.status": "Status",
  "pages.users.searchTable.actions": "Actions",
  "pages.users.searchTable.lastName": "Last Name",
  "pages.users.searchTable.firstName": "First Name",
  "pages.users.searchTable.email": "Email",

  "pages.users.searchTable.updateForm.ruleConfig": "Update User",
  "pages.users.searchTable.updateForm.userInfo": "User Information",
  "pages.users.searchTable.updateForm.username": "User Name",
  "pages.users.searchTable.updateForm.ruleName.userName":
    "Please Enter User Name",
  "pages.users.searchTable.updateForm.id": "ID",
  "pages.users.searchTable.updateForm.firstName": "First Name",
  "pages.users.searchTable.updateForm.ruleName.firstName":
    "Please Enter First Name",
  "pages.users.searchTable.updateForm.lastName": "Last Name",
  "pages.users.searchTable.updateForm.ruleName.lastName":
    "Please Enter Last Name",
  "pages.users.searchTable.updateForm.email": "Email",
  "pages.users.searchTable.updateForm.ruleName.email": "Please Enter Email",
  "pages.users.searchTable.updateForm.ruleName.email.format":
    "Please Enter Correct Email Format",
  "pages.users.searchTable.updateForm.phone": "Phone",
  "pages.users.searchTable.updateForm.ruleName.phone":
    "Please Enter Phone Number (at least 6 number)",
  "pages.users.searchTable.updateForm.dob": "Date of Birth",
  "pages.users.searchTable.updateForm.idno": "ID",
  "pages.users.searchTable.updateForm.ruleIDNO": "Please Enter ID no.",
  "pages.users.searchTable.updateForm.documents": "Documents",
  "pages.users.searchTable.updateForm.userRole": "Role(s)",

  "pages.users.searchTable.createForm.newUser": "Add User",
  "pages.users.searchTable.createForm.id": "ID",
  "pages.users.searchTable.createForm.ruleID": "Please Enter ID No.",
  "pages.users.searchTable.createForm.username": "User Name",
  "pages.users.searchTable.createForm.ruleUserName": "Please Enter User Name",
  "pages.users.searchTable.createForm.password": "Password",
  "pages.users.searchTable.createForm.rulePassword":
    "Please Enter Password (at least 3 characters)",
  "pages.users.searchTable.createForm.firstName": "First Name",
  "pages.users.searchTable.createForm.ruleFirstName": "Please Enter First Name",
  "pages.users.searchTable.createForm.lastName": "Last Name",
  "pages.users.searchTable.createForm.ruleLastName": "Please Enter Last Name",

  "pages.users.searchTable.createForm.email": "Email",
  "pages.users.searchTable.createForm.ruleEmail": "Please Enter Email",
  "pages.users.searchTable.createForm.phone": "Phone",
  "pages.users.searchTable.createForm.rulePhone":
    "Please Enter Phone Number (at least 6 number)",
  "pages.users.searchTable.createForm.roleID": "Role(s)",
  "pages.users.searchTable.createForm.ruleRoleID": "Please Select Role ID",

  "pages.users.searchTable.createForm.PIN": "PIN",
  "pages.users.searchTable.createForm.rulePIN": "Please Enter 4 digits PIN",
  "pages.users.searchTable.createForm.dob": "Date of Birth",

  "pages.users.searchTable.activateForm.ruleConfig": "Activate User",

  "pages.users.searchTable.deleteForm.ruleConfig": "Remove User",
  "pages.users.searchTable.deleteForm.id": "ID",
  "pages.users.searchTable.deleteForm.username": "username",
  "pages.users.searchTable.deleteForm.PIN": "Confirm with your 4-digit PIN",
  "pages.users.searchTable.deleteForm.rulePIN.PIN": "Please Enter 4-digit PIN",

  //payment log page
  "pages.paymentLog.showForm.title": "Payment Log Record",
  "pages.paymentLog.showForm.merchant": "Merchant",
  "pages.paymentLog.showForm.dateTime": "Date Time",
  "pages.paymentLog.showForm.type": "Type",
  "pages.paymentLog.showForm.direction": "Direction",
  "pages.paymentLog.showForm.paymentGateway": "Payment Gateway",
  "pages.paymentLog.showForm.paymentMethod": "Payment Method",
  "pages.paymentLog.showForm.txnID": "Txn ID",
  "pages.paymentLog.showForm.button.close": "Close",
  "pages.paymentLog.showForm.button.copy": "Copy Details",
  "pages.paymentLog.showForm.merchantCode": "Merchant Code",
  "pages.paymentLog.showForm.jsonData": "Detail",

  "pages.settings.payment.log.searchTable.title": "Log List",
  "pages.settings.payment.log.searchTable.dateTime": "Date Time",
  "pages.settings.payment.log.searchTable.merchant": "Merchant",
  "pages.settings.payment.log.searchTable.type": "Type",
  "pages.settings.payment.log.searchTable.direction": "Direction",
  "pages.settings.payment.log.searchTable.paymentGateway": "Payment Gateway",
  "pages.settings.payment.log.searchTable.paymentMethod": "Payment Method",
  "pages.settings.payment.log.searchTable.txnId": "Txn ID",

  //payment transaction page
  "pages.settings.payment.transaction.searchTable.title": "Transaction List",
  "pages.settings.payment.transaction.searchTable.createdDate": "Created Date",
  "pages.settings.payment.transaction.searchTable.paidDate": "Paid Date",
  "pages.settings.payment.transaction.searchTable.currency": "Currency",
  "pages.settings.payment.transaction.searchTable.amount": "Amount",
  "pages.settings.payment.transaction.searchTable.expiredDate": "Expired Date",
  "pages.settings.payment.transaction.searchTable.merchantID": "Merchant Code",
  "pages.settings.payment.transaction.searchTable.orderID": "Order ID",
  "pages.settings.payment.transaction.searchTable.paymentProvider":
    "Payment Provider",
  "pages.settings.payment.transaction.searchTable.paymentMethod":
    "Payment Method",
  "pages.settings.payment.transaction.searchTable.status": "Status",
  "pages.settings.payment.transaction.searchTable.txnID": "Transaction ID",
  "pages.settings.payment.transaction.searchTable.country": "Country",
  "pages.settings.payment.transaction.searchTable.countryCode": "Country Code",
  "pages.settings.payment.transaction.searchTable.mobileNo": "Mobile No",
  "pages.settings.payment.transaction.searchTable.paymentCode": "Payment Code",
  "pages.settings.payment.transaction.searchTable.orderExtraInfo":
    "Order Extra Info",

  "pages.settings.payment.transaction.search.dateRange": "Select Date Range",

  "pages.payment.transaction.showForm.title": "Payment Transaction Record",
  "pages.payment.transaction.button.copy": "Copy To Clipboard",
  "pages.payment.transaction.button.close": "Close",
  "pages.payment.transaction.showForm.merchant": "Merchant",
  "pages.payment.transaction.showForm.dateTime": "Date Time",
  "pages.payment.transaction.showForm.type": "Type",
  "pages.payment.transaction.showForm.direction": "Direction",
  "pages.payment.transaction.showForm.paymentGateway": "Payment Gateway",
  "pages.payment.transaction.showForm.paymentMethod": "Payment Method",
  "pages.payment.transaction.showForm.detail": "Detail",

  //setting page
  "pages.settings.country.searchTable.title": "Country List",
  "pages.settings.country.searchTable.name": "Name",
  "pages.settings.country.searchTable.code": "Code",
  "pages.settings.country.searchTable.status": "Status",
  "pages.settings.country.searchTable.actions": "Actions",

  "pages.settings.country.createTable.title": "Add Country",
  "pages.settings.country.createTable.name": "Name",
  "pages.settings.country.createTable.ruleName": "Please Enter Name",
  "pages.settings.country.createTable.code": "Code",
  "pages.settings.country.createTable.ruleCode": "Please Enter Code, eg: ID",
  "pages.settings.country.createTable.language": "Language",
  "pages.settings.country.createTable.ruleLanguage": "Please Enter Language",

  "pages.settings.country.updateForm.title": "Update Country",
  "pages.settings.country.updateForm.code": "Code",
  "pages.settings.country.updateForm.ruleCode": "Please Enter Code",
  "pages.settings.country.updateForm.name": "Name",
  "pages.settings.country.updateForm.ruleName": "Please Enter Name",
  "pages.settings.country.updateForm.language": "Language",

  "pages.settings.country.activateForm.id": "ID",

  "pages.settings.country.deleteForm.id": "ID",

  "pages.settings.currency.searchTable.title": "Currency List",
  "pages.settings.currency.searchTable.currencyCode": "Currency Code",
  "pages.settings.currency.searchTable.name": "Name",
  "pages.settings.currency.searchTable.currencySymbol": "Currency Symbol",
  "pages.settings.currency.searchTable.currencyDecimalPoint":
    "Currency Decimal Point",
  "pages.settings.currency.searchTable.minimumAmount": "Minimum Amount",

  "pages.settings.currency.createTable.title": "Add Currency",
  "pages.settings.currency.createTable.currencyCode": "Currency Code",
  "pages.settings.currency.createTable.ruleCurrencyCode":
    "Please Enter Currency Code, eg: IDR",
  "pages.settings.currency.createTable.name": "Name",
  "pages.settings.currency.createTable.ruleName": "Please Enter Name",
  "pages.settings.currency.createTable.currencySymbol": "Currency Symbol",
  "pages.settings.currency.createTable.ruleCurrencySymbol":
    "Please Enter Currency Symbol",
  "pages.settings.currency.createTable.minimumAmount": "Minimum Amount",
  "pages.settings.currency.createTable.ruleMinimumAmount":
    "Please Enter Minimum Amount Number, eg: 10",

  "pages.settings.currency.updateForm.title": "Update Currency",
  "pages.settings.currency.updateForm.code": "Code",
  "pages.settings.currency.updateForm.name": "Name",
  "pages.settings.currency.updateForm.ruleName": "Name is required",
  "pages.settings.currency.updateForm.currencySymbol": "Currency Symbol",
  "pages.settings.currency.updateForm.ruleCurrencySymbol":
    "Currency Symbol is required",
  "pages.settings.currency.updateForm.minimumAmount": "Minimum Amount",
  "pages.settings.currency.updateForm.ruleMinimumAmount":
    "Minimum Amount is required",
  "pages.settings.currency.updateForm.active": "Active",
  "pages.settings.currency.updateForm.id": "ID",

  "pages.settings.currency.activateForm.id": "ID",

  "pages.settings.currency.deleteForm.id": "ID",

  "pages.settings.merchant.searchTable.title": "Merchant List",
  "pages.settings.merchant.searchTable.code": "Merchant Code",
  "pages.settings.merchant.searchTable.merchantCategory": "Merchant Category",
  "pages.settings.merchant.searchTable.name": "Name",
  "pages.settings.merchant.searchTable.country": "Country",
  "pages.settings.merchant.searchTable.key": "Merchant Key",
  "pages.settings.merchant.searchTable.status": "Status",
  "pages.settings.merchant.searchTable.actions": "Actions",

  "pages.settings.merchant.createTable.title": "Add Merchant",
  "pages.settings.merchant.createTable.code": "Merchant Code",
  "pages.settings.merchant.createTable.ruleCode": "Merchant Code is required",
  "pages.settings.merchant.createTable.name": "Merchant Name",
  "pages.settings.merchant.createTable.ruleName": "Merchant Name is required",
  "pages.settings.merchant.createTable.key": "Merchant Key",
  "pages.settings.merchant.createTable.ruleKey": "Merchant Key is required",
  "pages.settings.merchant.createTable.country": "Country",
  "pages.settings.merchant.createTable.ruleCountry": "Country is required",
  "pages.settings.merchant.createTable.merchantCategory": "Merchant Category",
  "pages.settings.merchant.createTable.ruleMerchantCategory":
    "Merchant Category is required",

  "pages.settings.merchant.createTable.channel": "Channel",
  "pages.settings.merchant.createTable.displayName": "Display Name",
  "pages.settings.merchant.createTable.contact": "Contact Number",
  "pages.settings.merchant.createTable.actions": "Actions",
  "pages.settings.merchant.createTable.customerSupport":
    "Customer Support List",
  "pages.settings.merchant.createTable.addButton": "Add",

  "pages.settings.merchant.updateForm.title": "Update Merchant",
  "pages.settings.merchant.updateForm.merchantCode": "Merchant Code",
  "pages.settings.merchant.updateForm.name": "Merchant Name",
  "pages.settings.merchant.updateForm.ruleName": "Name is required",
  "pages.settings.merchant.updateForm.country": "Country",
  "pages.settings.merchant.updateForm.ruleCountry": "Country is required",
  "pages.settings.merchant.updateForm.merchantKey": "Merchant Key",
  "pages.settings.merchant.updateForm.ruleMerchantKey":
    "Merchant Key is required",
  "pages.settings.merchant.updateForm.merchantCategory": "Merchant Category",
  "pages.settings.merchant.updateForm.ruleMerchantCategory":
    "Merchant Category is required",
  "pages.settings.merchant.updateForm.active": "Active",
  "pages.settings.merchant.updateForm.id": "ID",

  "pages.settings.merchant.activateForm.id": "ID",
  "pages.settings.merchant.deleteForm.id": "ID",

  "pages.settings.merchantCategory.searchTable.title": "Merchant Category List",
  "pages.settings.merchantCategory.searchTable.code": "Code",
  "pages.settings.merchantCategory.searchTable.name": "Name",
  "pages.settings.merchantCategory.searchTable.status": "Status",
  "pages.settings.merchantCategory.searchTable.actions": "Actions",

  "pages.settings.merchantCategory.createTable.title": "Add Merchant Category",
  "pages.settings.merchantCategory.createTable.code": "Code",
  "pages.settings.merchantCategory.createTable.ruleCode":
    "Please Enter Code, eg: BS",
  "pages.settings.merchantCategory.createTable.name": "Name",
  "pages.settings.merchantCategory.createTable.ruleName": "Name is required",

  "pages.settings.merchantCategory.updateForm.title":
    "Update Merchant Category",
  "pages.settings.merchantCategory.updateForm.active": "Active",
  "pages.settings.merchantCategory.updateForm.id": "ID",
  "pages.settings.merchantCategory.updateForm.code": "Code",
  "pages.settings.merchantCategory.updateForm.name": "Name",
  "pages.settings.merchantCategory.updateForm.ruleName": "Name is required",

  "pages.settings.merchantCategory.activateForm.id": "ID",
  "pages.settings.merchantCategory.deleteForm.id": "ID",

  "pages.settings.systemconfig.searchTable.title": "System Config List",
  "pages.settings.systemconfig.searchTable.code": "Code",
  "pages.settings.systemconfig.searchTable.value": "Value",
  "pages.settings.systemconfig.searchTable.status": "Status",
  "pages.settings.systemconfig.searchTable.actions": "Actions",

  "pages.settings.systemconfig.createTable.title": "Add System Config",
  "pages.settings.systemconfig.createTable.code": "Code",
  "pages.settings.systemconfig.createTable.ruleCode": "Please Enter Code",
  "pages.settings.systemconfig.createTable.hasFile": "File Upload",
  "pages.settings.systemconfig.createTable.value": "Value",
  "pages.settings.systemconfig.createTable.fileName": "File Name",
  "pages.settings.systemconfig.createTable.ruleValue": "Please Enter Value",
  "pages.settings.systemconfig.createTable.uploadFile": "File",
  "pages.settings.systemconfig.createTable.ruleUploadFile":
    "Please Select File",

  "pages.settings.systemconfig.updateForm.title": "Update System Config",
  "pages.settings.systemconfig.updateForm.code": "Code",
  "pages.settings.systemconfig.updateForm.ruleCode": "Please Enter Code",
  "pages.settings.systemconfig.updateForm.fileTitle": "Upload File",
  "pages.settings.systemconfig.updateForm.uploadFile": "Upload File",
  "pages.settings.systemconfig.updateForm.value": "Value",
  "pages.settings.systemconfig.updateForm.fileName": "File Name",
  "pages.settings.systemconfig.updateForm.ruleValue": "Please Enter Value",
  "pages.settings.systemconfig.updateForm.active": "Active",
  "pages.settings.systemconfig.updateForm.id": "ID",
  "pages.settings.systemconfig.updateForm.fileId": "File ID",

  "pages.settings.systemconfig.activateForm.ID": "ID",
  "pages.settings.systemconfig.deleteForm.ID": "ID",

  "pages.settings.payment.gateway.searchTable.title": "Payment Gateway List",
  "pages.settings.payment.gateway.searchTable.name": "Name",
  "pages.settings.payment.gateway.searchTable.code": "Code",
  "pages.settings.payment.gateway.searchTable.status": "Status",
  "pages.settings.payment.gateway.searchTable.actions": "Actions",

  "pages.settings.payment.gateway.createForm.title": "Add Payment Gateway",
  "pages.settings.payment.gateway.createForm.code": "Code",
  "pages.settings.payment.gateway.createForm.ruleCode":
    "Please Enter Code, eg: IPAY88",
  "pages.settings.payment.gateway.createForm.name": "Name",
  "pages.settings.payment.gateway.createForm.ruleName": "Name is required",
  "pages.settings.payment.gateway.createForm.privateKey": "Private Key",
  "pages.settings.payment.gateway.createForm.publicKey": "Public Key",
  "pages.settings.payment.gateway.createForm.extMerchantID":
    "External Merchant ID",
  "pages.settings.payment.gateway.createForm.gatewayLink": "Gateway Link",
  "pages.settings.payment.gateway.createForm.gatewayFee": "Gateway Fee (%)",
  "pages.settings.payment.gateway.createForm.webhookId": "Webhook ID",

  "pages.settings.payment.gateway.updateForm.title": "Update Payment Gateway",
  "pages.settings.payment.gateway.updateForm.id": "ID",
  "pages.settings.payment.gateway.updateForm.createdBy": "Created By",
  "pages.settings.payment.gateway.updateForm.created": "Created",
  "pages.settings.payment.gateway.updateForm.active": "Active",
  "pages.settings.payment.gateway.updateForm.modified": "Modified",
  "pages.settings.payment.gateway.updateForm.modifiedBy": "Modified By",
  "pages.settings.payment.gateway.updateForm.version": "Version",
  "pages.settings.payment.gateway.updateForm.code": "Code",
  "pages.settings.payment.gateway.updateForm.name": "Name",
  "pages.settings.payment.gateway.updateForm.ruleName": "Please Enter Name",
  "pages.settings.payment.gateway.updateForm.privateKey": "Private Key",
  "pages.settings.payment.gateway.updateForm.publicKey": "Public Key",
  "pages.settings.payment.gateway.updateForm.extMerchantID":
    "External Merchant ID",
  "pages.settings.payment.gateway.updateForm.gatewayLink": "Gateway Link",
  "pages.settings.payment.gateway.updateForm.gatewayFee": "Gateway Fee (%)",
  "pages.settings.payment.gateway.updateForm.webhookId": "Webhook ID",

  "pages.settings.payment.gateway.activateForm.id": "ID",
  "pages.settings.payment.gateway.deleteForm.id": "ID",

  "pages.settings.payment.config.createForm.title": "Add Payment Config",
  "pages.settings.payment.config.createForm.name": "Name",
  "pages.settings.payment.config.createForm.ruleName": "Please Enter Name",
  "pages.settings.payment.config.createForm.maxRetries": "Max Retries",
  "pages.settings.payment.config.createForm.ruleMaxRetries":
    "Please Enter A Number, eg: 0",
  "pages.settings.payment.config.createForm.retriesInterval":
    "Retries Interval(s)",
  "pages.settings.payment.config.createForm.ruleRetriesInterval":
    "Please Enter A Number, eg: 0",
  "pages.settings.payment.config.createForm.successfulRedirectUrl":
    "Successful Redirect URL",
  "pages.settings.payment.config.createForm.failureRedirectURL":
    "Failure Redirect URL",

  "pages.settings.payment.config.searchTable.title": "Payment Config List",
  "pages.settings.payment.config.searchTable.id": "ID",
  "pages.settings.payment.config.searchTable.name": "Name",
  "pages.settings.payment.config.searchTable.maxRetries": "Max Retries",
  "pages.settings.payment.config.searchTable.retriesInterval":
    "Retries Interval(s)",
  "pages.settings.payment.config.searchTable.successfulRedirectURL":
    "Successful Redirect URL",
  "pages.settings.payment.config.searchTable.failureRedirectURL":
    "Failure Redirect URL",
  "pages.settings.payment.config.searchTable.status": "Status",
  "pages.settings.payment.config.searchTable.actions": "Actions",

  "pages.settings.payment.config.updateForm.title": "Update Payment Config",
  "pages.settings.payment.config.updateForm.name": "Name",
  "pages.settings.payment.config.updateForm.ruleName": "Please Enter Name",
  "pages.settings.payment.config.updateForm.maxRetries": "Max Retries",
  "pages.settings.payment.config.updateForm.ruleMaxRetries":
    "Please Enter A Number",
  "pages.settings.payment.config.updateForm.retriesInterval":
    "Retries Interval(s)",
  "pages.settings.payment.config.updateForm.ruleRetriesInterval":
    "Please Enter A Number",
  "pages.settings.payment.config.updateForm.successfulRedirectURL":
    "Successful Redirect URL",
  "pages.settings.payment.config.updateForm.failureRedirectURL":
    "Failure Redirect URL",

  "pages.settings.payment.config.deleteForm.id": "ID",

  "pages.settings.payment.option.searchTable.title": "Payment Option List",
  "pages.settings.payment.option.searchTable.name": "Name",
  "pages.settings.payment.option.searchTable.country": "Country",
  "pages.settings.payment.option.searchTable.currency": "Currency",
  "pages.settings.payment.option.searchTable.paymentGateway": "Payment Gateway",
  "pages.settings.payment.option.searchTable.paymentConfig": "Payment Config",
  "pages.settings.payment.option.searchTable.paymentType": "Payment Type",
  "pages.settings.payment.option.searchTable.status": "Status",
  "pages.settings.payment.option.searchTable.active": "ACTIVE",
  "pages.settings.payment.option.searchTable.inactive": "INACTIVE",
  "pages.settings.payment.option.searchTable.actions": "Actions",

  "pages.settings.payment.option.createForm.title": "Add Payment Option",
  "pages.settings.payment.option.createForm.paymentChannel": "Payment Channel",
  "pages.settings.payment.option.createForm.rulePaymentChannel":
    "Payment Channel is required",
  "pages.settings.payment.option.createForm.name": "Name",
  "pages.settings.payment.option.createForm.ruleName": "Name is required",
  "pages.settings.payment.option.createForm.country": "Country",
  "pages.settings.payment.option.createForm.ruleCountry": "Country is required",
  "pages.settings.payment.option.createForm.paymentGateway": "Payment Gateway",
  "pages.settings.payment.option.createForm.rulePaymentGateway":
    "Payment Gateway is required",
  "pages.settings.payment.option.createForm.paymentConfig": "Payment Config",
  "pages.settings.payment.option.createForm.rulePaymentConfig":
    "Payment Config is required",
  "pages.settings.payment.option.createForm.paymentType": "Payment Type",
  "pages.settings.payment.option.createForm.rulePaymentType":
    "Payment Type is required",
  "pages.settings.payment.option.createForm.currency": "Currency",
  "pages.settings.payment.option.createForm.ruleCurrency":
    "Currency is required, eg: MYR, IDRS",
  "pages.settings.payment.option.createForm.minAmount": "Minimum Amount",
  "pages.settings.payment.option.createForm.ruleMinAmount":
    "Minimum Amount should be number, eg: 0",
  "pages.settings.payment.option.createForm.maxAmount": "Maximum Amount",
  "pages.settings.payment.option.createForm.ruleMaxAmount":
    "Maximum Amount should be number, eg: 0",
  "pages.settings.payment.option.createForm.discountPercentage": "Discount %",
  "pages.settings.payment.option.createForm.ruleDiscountPercentage":
    "Discount eg: 23, 45.08, 98.3",
  "pages.settings.payment.option.createForm.gatewayFee": "Gateway Fee (%)",
  "pages.settings.payment.option.createForm.ruleGatewayFee":
    "Gateway Fee (%) eg: 23, 45.08, 98.3",
  "pages.settings.payment.option.createForm.sequenceIndex": "Sequence Index",
  "pages.settings.payment.option.createForm.callbackURL": "Callback URL",
  "pages.settings.payment.option.createForm.requireLink": "Require Link",
  "pages.settings.payment.option.createForm.ruleRequireLink":
    "Require Link is required",
  "pages.settings.payment.option.createForm.upload": "Upload",

  "pages.settings.payment.option.updateForm.title": "Update Payment Option",
  "pages.settings.payment.option.updateForm.id": "ID",
  "pages.settings.payment.option.updateForm.paymentChannel": "Payment Channel",
  "pages.settings.payment.option.updateForm.ruleRequireLink":
    "Payment Channel is required",
  "pages.settings.payment.option.updateForm.name": "Name",
  "pages.settings.payment.option.updateForm.ruleName": "Name is required",
  "pages.settings.payment.option.updateForm.country": "Country",
  "pages.settings.payment.option.updateForm.ruleCountry": "Country is required",
  "pages.settings.payment.option.updateForm.merchant": "Merchant",
  "pages.settings.payment.option.updateForm.paymentGateway": "Payment Gateway",
  "pages.settings.payment.option.updateForm.rulePaymentGateway":
    "Payment Gateway is required",
  "pages.settings.payment.option.updateForm.paymentConfig": "Payment Config",
  "pages.settings.payment.option.updateForm.paymentType": "Payment Type",
  "pages.settings.payment.option.updateForm.rulePaymentType":
    "Payment Type is required",
  "pages.settings.payment.option.updateForm.currency": "Currency",
  "pages.settings.payment.option.updateForm.ruleCurrency":
    "Currency is required, eg: MYR, IDRS",
  "pages.settings.payment.option.updateForm.minAmount": "Minimum Amount",
  "pages.settings.payment.option.updateForm.ruleMinAmount":
    "Minimum Amount should be number, eg: 0",
  "pages.settings.payment.option.updateForm.maxAmount": "Maximum Amount",
  "pages.settings.payment.option.updateForm.ruleMaxAmount":
    "Maximum Amount should be number, eg: 0",
  "pages.settings.payment.option.updateForm.discountPercentage": "Discount %",
  "pages.settings.payment.option.updateForm.ruleDiscountPercentage":
    "Discount eg: 23, 45.08, 98.3",
  "pages.settings.payment.option.updateForm.gatewayFee": "Gateway Fee (%)",
  "pages.settings.payment.option.updateForm.ruleGatewayFee":
    "Gateway Fee (%) eg: 23, 45.08, 98.3",
  "pages.settings.payment.option.updateForm.sequenceIndex": "Sequence Index",
  "pages.settings.payment.option.updateForm.callbackUrl": "Callback URL",
  "pages.settings.payment.option.updateForm.requireLink": "Require Link",
  "pages.settings.payment.option.updateForm.upload": "Upload",

  "pages.settings.payment.option.deleteForm.id": "ID",
  "pages.settings.payment.option.activateForm.id": "ID",

  "pages.settings.payment.displayType.searchTable.name": "Display",
  "pages.settings.payment.displayType.searchTable.language": "Language",
  "pages.settings.payment.displayType.searchTable.paymentMethod":
    "Payment Method",
  "pages.settings.payment.displayType.searchTable.status": "Status",
  "pages.settings.payment.displayType.searchTable.actions": "Options",
  "pages.settings.payment.displayType.searchTable.title":
    "Payment Type Display List",

  "pages.settings.payment.displayType.createForm.title":
    "Add Payment Type Display",
  "pages.settings.payment.displayType.createForm.display": "Display",
  "pages.settings.payment.displayType.createForm.ruleDisplay":
    "Display is required",
  "pages.settings.payment.displayType.createForm.language": "Language",
  "pages.settings.payment.displayType.createForm.ruleLanguage":
    "Language is required",
  "pages.settings.payment.displayType.createForm.paymentMethod":
    "Payment Method",
  "pages.settings.payment.displayType.createForm.rulePaymentMethod":
    "Payment Method is required",

  "pages.settings.payment.displayType.updateForm.title":
    "Update Payment Type Display",
  "pages.settings.payment.displayType.updateForm.id": "ID",
  "pages.settings.payment.displayType.updateForm.display": "Display",
  "pages.settings.payment.displayType.updateForm.ruleDisplay":
    "Display is required",
  "pages.settings.payment.displayType.updateForm.language": "Language",
  "pages.settings.payment.displayType.updateForm.ruleLanguage":
    "Language is required",
  "pages.settings.payment.displayType.updateForm.paymentMethod":
    "Payment Method",
  "pages.settings.payment.displayType.updateForm.rulePaymentMethod":
    "Payment Method is required",

  "pages.settings.payment.displayType.activateForm.id": "ID",
  "pages.settings.payment.displayType.deleteForm.id": "ID",

  // CustomerSupport
  "pages.CustomerSupport.searchTable.createForm.title": "Add Customer Support",
  "pages.CustomerSupport.searchTable.createForm.channel": "Channel",
  "pages.CustomerSupport.searchTable.createForm.country": "Country",
  "pages.CustomerSupport.searchTable.createForm.merchant": "Merchant",
  "pages.CustomerSupport.searchTable.createForm.name": "Display",
  "pages.CustomerSupport.searchTable.createForm.contactNumber":
    "Contact Number",

  "pages.customer.support.searchTable.title": "Customer Support List",
  "pages.CustomerSupport.searchTable.actions": "Actions",
  "pages.CustomerSupport.searchTable.channel": "Channel",
  "pages.CustomerSupport.searchTable.country": "Country",
  "pages.CustomerSupport.searchTable.merchant": "Merchant",
  "pages.CustomerSupport.searchTable.name": "Display",
  "pages.CustomerSupport.searchTable.contactNumber": "Contact Number",

  "pages.CustomerSupport.searchTable.createForm.rule.channel":
    "Please select Channel",
  "pages.CustomerSupport.searchTable.createForm.rule.country":
    "Please select Country",
  "pages.CustomerSupport.searchTable.createForm.rule.merchant":
    "Please select Merchant",
  "pages.CustomerSupport.searchTable.createForm.rule.name":
    "Please Enter Display",
  "pages.CustomerSupport.searchTable.createForm.rule.contactNumber":
    "Please Enter Contact Number",

  "pages.ChangePassword.password": "New Password",
  "pages.ChangePassword.rule.password": "Please Enter New Password",
  "pages.ChangePassword.confirm.password": "Confirm New Password",
  "pages.ChangePassword.rule.confirm.password":
    "Please Enter Confirm New Password",

  // Common
  "pages.common.action": "Action",
  "pages.common.add": "Add",
  "pages.common.update": "Update",
  "pages.common.confirm": "Confirm",
  "pages.common.copy-success": "Copied Successful",
  "pages.common.temperature": "Temperature",
  "pages.common.voltage": "Voltage",
  "pages.common.vol-curve": "Voltage Curve",
  "pages.common.current": "Current",
  "pages.common.signal": "Signal",
  "pages.common.export-csv": "Export CSV Data",
  "pages.common.currency": "{currency} {number}",
  "pages.common.day": "Day",
  "pages.common.month": "Month",
  "pages.common.year": "Year",
  "pages.common.update-success": "Update Success",
  "pages.common.success-operation": "Successful operation",
  "pages.common.remind": "Remind",
  "pages.common.tip": "Tip",
  "pages.common.delete-success": "Deleted Successful",
  "pages.common.tooltip": "Tooltip",
  "pages.common.submit": "Submit",
  "pages.common.edit": "Edit",
  "pages.common.required": "This field is required",
  "pages.common.delete": "Delete",
  "pages.common.screenshot": "Screenshot",
  "pages.common.export-data": "Export Data",
  "pages.common.download": "Download",
  "pages.common.location": "Location",
  "pages.common.latitude": "Latitude",
  "pages.common.longitude": "Longitude",
  "pages.common.upload-image-title":
    "Click or drag the image file to this area to upload",
  // Error
  "pages.error.something-wrong": "Something went wrong!!!",
  "pages.error.param-empty": "Parameter cannot be empty",
  "pages.common.back": "Back",

  // Swapping Battery Order
  "pages.orders.swappingBatteryOrder.searchTable.title":
    "Battery Swapping Order",
  "pages.orders.swappingBatteryOrder.orderNumber": "Order Number",
  "pages.orders.swappingBatteryOrder.paymentType": "Payment Type",
  "pages.orders.swappingBatteryOrder.orderTime": "Order Time",
  "pages.orders.swappingBatteryOrder.paymentAmount": "Payment Amount",
  "pages.orders.swappingBatteryOrder.orderStatus": "Order Status",
  "pages.orders.swappingBatteryOrder.userName": "User Name",
  "pages.orders.swappingBatteryOrder.userPhone": "Cell-phone number",

  // Swapping Battery Order Detail
  "pages.orders.swappingBatteryOrderDetail.orderNumber": "Order Number",
  "pages.orders.swappingBatteryOrderDetail.orderPlatform": "Order Platform",
  "pages.orders.swappingBatteryOrderDetail.orderStartTime": "Order Start Time",
  "pages.orders.swappingBatteryOrderDetail.orderType": "Order Type",
  "pages.orders.swappingBatteryOrderDetail.paymentType": "Payment Type",
  "pages.orders.swappingBatteryOrderDetail.orderEndTime": "Order End Time",
  "pages.orders.swappingBatteryOrderDetail.orderStatus": "Order Status",
  "pages.orders.swappingBatteryOrderDetail.batterySwapNo":
    "Number of battery swapping",
  "pages.orders.swappingBatteryOrderDetail.batteryNo": "Battery No. {number}",
  "pages.orders.swappingBatteryOrderDetail.batteryName": "Battery Name",
  "pages.orders.swappingBatteryOrderDetail.batteryNumber": "Battery Number",
  "pages.orders.swappingBatteryOrderDetail.onlineStatus": "Online Status",
  "pages.orders.swappingBatteryOrderDetail.businessStatus": "Business Status",
  "pages.orders.swappingBatteryOrderDetail.currentElectricity":
    "Current Electricity",
  "pages.common.details": "Details",
  "pages.cabinet.update-soc-success": "Update SOC Successful!",
  "pages.cabinet.update-soc-failed": "Failed to Update SOC!",
  "pages.cabinet.update-soc-error-validation":
    "The SOC value must be between 0 and 100!",
  "pages.cabinet.update-soc-not-empty-validation":
    "The SOC value can't be empty!",
  "pages.cabinet.update-soc-selected": "Selected",

  // Cabinet Type
  "pages.cabinet-type.success-add-new": "Add New Cabinet Type Successful!",
  "pages.cabinet-type.success-update": "Update Cabinet Type Successful!",
  "pages.cabinet-type.failed-add-new": "Failed to Add New Cabinet Type!",
  "pages.cabinet-type.failed-update": "Failed to Upadate Cabinet Type!",
  "pages.cabinet-type.empty-validation-message": "can't be empty!",
  "pages.cabinet-type.upload": "Cabinet Image"
};
